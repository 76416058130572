import React from 'react'

import { Box, Grid } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CreateExtCompareConnectLeadMutation,
  CreateExtCompareConnectLeadMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { IntegrationLogo } from 'src/components/CustomerIntegrations/IntegrationCard'
import RecordComponent from 'src/components/Revenue/Profiles/RecordComponent'
import { QUERY } from 'src/components/Revenue/Profiles/RevenueProfilesDetailsCell/RevenueProfilesDetailsCell'

import {
  ccStatusToColor,
  innovaytStatusToColor,
  onCoverStatusToColor,
  relloStatusToColor,
} from './Utils'

const CREATE_COMPARE_CONNECT_LEAD = gql`
  mutation CreateExtCompareConnectLeadMutation($id: Int!) {
    createExtCompareConnectLead(id: $id) {
      leadId
      leadUrl
      status
    }
  }
`

interface FormProps {
  arrayList: any
  propertySingletonId: number
  contactType: string
}

const RecordComponentList: React.FC<FormProps> = ({
  arrayList,
  contactType,
  propertySingletonId,
}) => {
  const integrationItems = [
    { type: 'COMPARECONNECT', label: 'Compare Connect' },
    { type: 'RELLO', label: 'Rello' },
    { type: 'ONCOVER', label: 'On Cover' },
    { type: 'INNOVAYT', label: 'Innovayt' },
  ]

  const [createCompareConnectLead] = useMutation<
    CreateExtCompareConnectLeadMutation,
    CreateExtCompareConnectLeadMutationVariables
  >(CREATE_COMPARE_CONNECT_LEAD, {
    onCompleted: () => {
      toast.success('Lead Generated')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: [{ query: QUERY, variables: { id: propertySingletonId } }],
    awaitRefetchQueries: true,
  })

  const handleCreateCompareConnectLead = async (id: number) => {
    await createCompareConnectLead({ variables: { id } })
  }

  return (
    <Box
      className="overflow-y-auto
        py-2 px-5
        "
    >
      <Stack>
        {arrayList?.length === 0 && (
          <Typography className="flex flex-row justify-center items-center">
            No {contactType} available
          </Typography>
        )}
        {arrayList?.length > 0 && (
          <>
            <Grid container spacing={1} alignItems="center" className="pb-4">
              <Grid item xs={4} sm={4}>
                Contact
              </Grid>

              {integrationItems.map((item, index) => (
                <Grid key={index} item xs={2} sm={2} alignItems="center">
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    className="flex flex-row justify-center items-center"
                  >
                    <IntegrationLogo integrationType={item.type} size="small" />
                    <Typography className="flex flex-row justify-center items-center">
                      {item.label}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            {arrayList?.map((property) => (
              <RecordComponent
                key={property.id}
                profileName={`${property?.extProfile?.profileName}`}
                onClickFunction={() =>
                  handleCreateCompareConnectLead(property.id)
                }
                ccLeads={property?.extProfile?.extCompareConnectLead?.map(
                  (lead) => ({
                    status: lead.status,
                    color: ccStatusToColor(lead.status),
                    url: lead.extUrl,
                  }),
                )}
                relloLeads={property?.extProfile?.extRelloLead?.map((lead) => ({
                  status: lead.status,
                  color: relloStatusToColor(lead.status),
                  url: lead.extUrl,
                }))}
                onCoverLeads={property?.extProfile?.extOnCoverLead?.map(
                  (lead) => ({
                    status: lead.status,
                    color: onCoverStatusToColor(lead.status),
                    url: lead.extUrl,
                  }),
                )}
                innovaytLeads={property?.extProfile?.extInnovaytLead?.map(
                  (lead) => ({
                    status: lead.status,
                    color: innovaytStatusToColor(lead.status),
                    url: lead.extUrl,
                  }),
                )}
              />
            ))}
          </>
        )}
      </Stack>
    </Box>
  )
}

export default RecordComponentList
