import { CircularProgress, CircularProgressProps } from '@mui/material'

const Loading = (
  props?: { circularProgressProps?: CircularProgressProps } & {
    size?: 'small' | 'x-small'
  },
) => {
  let sizeClasses = '!w-16 !h-16'

  if (props.size === 'small') sizeClasses = '!w-6 !h-6'
  if (props.size === 'x-small') sizeClasses = '!w-4 !h-4'

  return (
    <div
      className="grid grid-cols-1 place-items-center w-full h-full m-auto text-indigo-600"
      data-testid="loading-component"
    >
      <CircularProgress
        className={`${sizeClasses}`}
        color={props?.circularProgressProps?.color ?? 'inherit'}
        {...props?.circularProgressProps}
      />
    </div>
  )
}

export default Loading
