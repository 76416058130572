import React, { useEffect, useState } from 'react'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useOs } from 'src/lib/hooks/UseOS'
import { handleHotkey } from 'src/Util'

import GlobalSearchButton from './GlobalSearchButton'

export type GlobalSearchModalClick = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  trackerName: string,
) => void

const GlobalSearch = ({ isMobile, isHomePage = false }) => {
  const [openGlobalSearchModal, setOpenGlobalSearchModal] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const { trackEvent } = useAnalytics()
  const os = useOs()

  const toggleGlobalSearchModal: GlobalSearchModalClick = (
    _event,
    trackerName,
  ) => {
    if (!openGlobalSearchModal) {
      setOpenGlobalSearchModal(true)
      trackEvent('Search', trackerName)
    } else {
      handleCloseGlobalSearch()
    }
  }

  const handleCloseGlobalSearch = () => {
    setSearchVal('')
    setOpenGlobalSearchModal(false)
    trackEvent('Search', 'GSB - close search')
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isHomePage) {
        return
      }
      if (os === 'linux') {
        handleHotkey(event, 'm', toggleGlobalSearchModal, os)
      } else {
        handleHotkey(event, 'k', toggleGlobalSearchModal, os)
      }
    }
    if (!isMobile) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [os, toggleGlobalSearchModal])

  return (
    <>
      <GlobalSearchButton
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        isMobile={isMobile}
        isHomePage={isHomePage}
        openGlobalSearchModal={openGlobalSearchModal}
        openGlobalSearch={toggleGlobalSearchModal}
        handleCloseGlobalSearch={handleCloseGlobalSearch}
      />
    </>
  )
}
export default GlobalSearch
