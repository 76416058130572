import React from 'react'

import {
  RevenueProfilesQuery,
  RevenueProfilesQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import RevenueProfilesHeader from '../RevenueProfilesHeader'

export const QUERY = gql`
  query RevenueProfilesQuery {
    extBuildingSingletonsGroupedByAddresses {
      id
      address
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  return <RevenueProfilesHeader addresses={[]} />
}

export const Failure = ({
  error,
}: CellFailureProps<RevenueProfilesQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type SuccessProps = CellSuccessProps<
  RevenueProfilesQuery,
  RevenueProfilesQueryVariables
>

export type CellProperties =
  SuccessProps['extBuildingSingletonsGroupedByAddresses']
export type CellProperty =
  SuccessProps['extBuildingSingletonsGroupedByAddresses'][]

export const Success = ({
  extBuildingSingletonsGroupedByAddresses,
}: SuccessProps) => {
  return (
    <RevenueProfilesHeader
      addresses={extBuildingSingletonsGroupedByAddresses}
    />
  )
}
