import React, { useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/20/solid'
import {
  CloudArrowUpIcon,
  EyeIcon,
  MinusCircleIcon,
} from '@heroicons/react/24/outline'
import { Grid, Stack, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  BASEROW_IMPORT_MATCH_TYPE,
  DetailedFieldRecord,
  DetailedTableRecord,
  MatchingBaserowFieldType,
} from 'api/src/common/baserowImporterTypes'
import Select from 'react-select'

import BaserowImporterModalFieldDetails from 'src/components/BaserowImporter/BaserowImporterModalFieldDetails'
import { getItemMatchStatus } from 'src/components/BaserowImporter/helpers'
import Button from 'src/components/Library/Button/Button'

interface BaserowImporterFieldComponentProps {
  fieldItem: DetailedFieldRecord
  handleFieldMatch: (airtableFieldId: number, baserowFieldId: number) => void
  deleteFieldMatch: (airtableFieldId: number, baserowFieldId: number) => void
  handleFieldIgnore: (fieldId: number) => void
  unmatchedBaserowFields: MatchingBaserowFieldType[]
  handleFieldCreate: (airtableFieldId: number) => void
  tableItem: DetailedTableRecord
  tableData: DetailedTableRecord[]
}

export type SelectOptionType = {
  value: number
  label: string
}

const BaserowImporterComponentFieldItem = ({
  fieldItem,
  handleFieldMatch,
  deleteFieldMatch,
  unmatchedBaserowFields,
  handleFieldCreate,
  handleFieldIgnore,
  tableItem,
  tableData,
}: BaserowImporterFieldComponentProps) => {
  const [options, setOptions] = useState<SelectOptionType[]>([])
  const [selectedRecord, setSelectedRecord] =
    useState<DetailedFieldRecord>(null)
  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    setOptions(
      unmatchedBaserowFields.map((field) => ({
        value: field.id,
        label: field.name,
      })),
    )
  }, [unmatchedBaserowFields, fieldItem])

  return (
    <>
      <Grid item xs={5}>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {getItemMatchStatus(fieldItem, 'Field')}

          <Typography className={'text-xs truncate'}>
            {fieldItem.name}
          </Typography>
          <Tooltip title={'Field Details'}>
            <div>
              <Button
                fullWidth={false}
                variant="text"
                className="min-w-0 rounded-full"
                onClick={() => {
                  setSelectedRecord(fieldItem)
                  setOpenModal(true)
                }}
              >
                <EyeIcon
                  className={`w-5 h-5
                    text-blue-600
                  `}
                />
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Grid>
      <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
        {fieldItem.matchingField ? (
          fieldItem.matchingField.matchType ===
          BASEROW_IMPORT_MATCH_TYPE.IGNORE ? (
            <Typography className={'capitalize text-xs truncate'}>
              Field will be ignored
            </Typography>
          ) : [
              BASEROW_IMPORT_MATCH_TYPE.MANUAL,
              BASEROW_IMPORT_MATCH_TYPE.DETECTED,
            ].includes(fieldItem.matchingField.matchType) ? (
            <Typography className={'capitalize text-xs truncate'}>
              {`${fieldItem.matchingField.name} - ID: ${fieldItem.matchingField.id}`}
            </Typography>
          ) : (
            <Typography className={'capitalize text-xs truncate'}>
              New Field Will Be Created
            </Typography>
          )
        ) : (
          <Select
            options={options}
            onChange={(selectedOption) => {
              handleFieldMatch(fieldItem.id, selectedOption.value)
            }}
            className={'text-xs w-full'}
          />
        )}
      </Grid>

      <Grid item xs={2}>
        <Stack direction={'row'} className={'w-full'} alignItems={'center'}>
          <Tooltip title={'Create New Field'}>
            <div>
              <Button
                disabled={fieldItem.matchingField?.matchType === null}
                fullWidth={false}
                variant="text"
                className="min-w-0 rounded-full"
                onClick={() => handleFieldCreate(fieldItem.id)}
              >
                <CloudArrowUpIcon
                  className={`w-5 h-5 ${
                    fieldItem.matchingField ? 'text-gray-300' : 'text-blue-600'
                  }`}
                />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={'Ignore Field'}>
            <div>
              <Button
                fullWidth={false}
                disabled={fieldItem.matchingField?.matchType === null}
                variant="text"
                className="min-w-0 rounded-full"
                onClick={() => handleFieldIgnore(fieldItem.id)}
              >
                <MinusCircleIcon
                  className={`w-5 h-5 ${
                    fieldItem.matchingField
                      ? 'text-gray-300'
                      : 'text-orange-600'
                  }`}
                />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={'Delete Match'}>
            <div>
              <Button
                fullWidth={false}
                disabled={!fieldItem.matchingField?.matchType}
                variant="text"
                className="min-w-0 rounded-full"
                onClick={() => deleteFieldMatch(fieldItem.id, fieldItem.id)}
              >
                <XMarkIcon
                  className={`w-5 h-5 ${
                    fieldItem.matchingField ? 'text-red-600' : 'text-gray-300'
                  }`}
                />
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Grid>
      {selectedRecord && (
        <BaserowImporterModalFieldDetails
          selectedRecord={selectedRecord}
          tableData={tableData}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          tableItem={tableItem}
        />
      )}
    </>
  )
}

export default BaserowImporterComponentFieldItem
