import {
  default as MuiAutoComplete,
  AutocompleteProps,
} from '@mui/material/Autocomplete'

type AutoCompleteProps<
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>

const AutoComplete = <
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>(
  props: AutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  if (props.getOptionKey) {
    return <MuiAutoComplete {...props} />
  } else {
    return (
      <MuiAutoComplete
        {...props}
        getOptionKey={(option: T) => (option as any).id}
      />
    )
  }
}

export default AutoComplete
