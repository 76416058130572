import React from 'react'

import {
  RevenueProfilesDetailQuery,
  RevenueProfilesDetailQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import RevenueProfiles from 'src/components/Revenue/Profiles/RevenueProfiles'

export const QUERY = gql`
  query RevenueProfilesDetailQuery($id: Int!) {
    extBuildingSingletonsGroupedByAddress(id: $id) {
      id
      address
      extBuildings {
        id
        extProfile {
          id
          profileName
          extCompareConnectLead {
            id
            status
            extUrl
          }
          extRelloLead {
            id
            status
            extUrl
          }
          extInnovaytLead {
            id
            status
            extUrl
          }
          extOnCoverLead {
            id
            status
            extUrl
          }
        }
        extManagements {
          id
          extProfile {
            id
            profileName
            extCompareConnectLead {
              id
              status
              extUrl
            }
            extRelloLead {
              id
              status
              extUrl
            }
            extInnovaytLead {
              id
              status
              extUrl
            }
            extOnCoverLead {
              id
              status
              extUrl
            }
          }

          extArrears {
            id
            extProfile {
              id
              profileName
              extCompareConnectLead {
                id
                status
                extUrl
              }
              extRelloLead {
                id
                status
                extUrl
              }
              extInnovaytLead {
                id
                status
                extUrl
              }
              extOnCoverLead {
                id
                status
                extUrl
              }
            }
          }
          extApplications {
            id
            status
            extProfile {
              id
              profileName
              extCompareConnectLead {
                id
                status
                extUrl
              }
              extRelloLead {
                id
                status
                extUrl
              }
              extInnovaytLead {
                id
                status
                extUrl
              }
              extOnCoverLead {
                id
                status
                extUrl
              }
            }
          }
        }
        extListings {
          id
          extProfile {
            id
            profileName
            extCompareConnectLead {
              id
              status
              extUrl
            }
            extRelloLead {
              id
              status
              extUrl
            }
            extInnovaytLead {
              id
              status
              extUrl
            }
            extOnCoverLead {
              id
              status
              extUrl
            }
          }
          extContracts {
            id
            extProfile {
              id
              profileName
              extCompareConnectLead {
                id
                status
                extUrl
              }
              extRelloLead {
                id
                status
                extUrl
              }
              extInnovaytLead {
                id
                status
                extUrl
              }
              extOnCoverLead {
                id
                status
                extUrl
              }
            }
          }
          extOffers {
            id
            extProfile {
              id
              profileName
              extCompareConnectLead {
                id
                status
                extUrl
              }
              extRelloLead {
                id
                status
                extUrl
              }
              extInnovaytLead {
                id
                status
                extUrl
              }
              extOnCoverLead {
                id
                status
                extUrl
              }
            }
          }
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  return <RevenueProfiles extSingletonProperty={null} />
}

export const Failure = ({
  error,
}: CellFailureProps<RevenueProfilesDetailQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type SuccessProps = CellSuccessProps<
  RevenueProfilesDetailQuery,
  RevenueProfilesDetailQueryVariables
>

export type CellDetailProperty =
  SuccessProps['extBuildingSingletonsGroupedByAddress']
export type BuildingsType = CellDetailProperty['extBuildings']
export type ListingsType = BuildingsType[0]['extListings']
export type ContractsType = ListingsType[0]['extContracts']
export type OffersType = ListingsType[0]['extOffers']
export type ManagementsType = BuildingsType[0]['extManagements']
export type ArrearsType = ManagementsType[0]['extArrears']
export type ApplicationsType = ManagementsType[0]['extApplications']

export const Success = ({
  extBuildingSingletonsGroupedByAddress,
}: SuccessProps) => {
  return (
    <RevenueProfiles
      extSingletonProperty={extBuildingSingletonsGroupedByAddress}
    />
  )
}
