import { FC } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { useOs } from 'src/lib/hooks/UseOS'

import Button from '../Library/Button/Button'
import PageHeaderIconButton from '../PageHeaderIconButton/PageHeaderIconButton'

import GlobalSearchModal from './GlobalSearchModal'

import { GlobalSearchModalClick } from '.'

interface GlobalSearchButtonProps {
  searchVal: string
  setSearchVal: (val: string) => void
  isMobile: boolean
  isHomePage?: boolean
  openGlobalSearchModal: boolean
  openGlobalSearch: GlobalSearchModalClick
  handleCloseGlobalSearch: () => void
}

const GlobalSearchButton: FC<GlobalSearchButtonProps> = ({
  searchVal,
  setSearchVal,
  isMobile,
  isHomePage,
  openGlobalSearchModal,
  openGlobalSearch,
  handleCloseGlobalSearch,
}) => {
  const os = useOs()
  let osSymbol = ''

  if (os === 'macos') {
    osSymbol = '⌘'
  } else {
    osSymbol = 'Ctrl+'
  }

  const buttonStyle = isHomePage
    ? 'bg-white text-gray-600 hover:text-gray-700 hover:bg-gray-50 border-gray-300 hover:border-gray-400 !rounded justify-between h-12 !w-full'
    : 'text-gray-400 hover:text-gray-500 hover:bg-gray-100 border-gray-400 hover:border-gray-300 justify-between !rounded'

  if (isMobile && !isHomePage) {
    return (
      <div id="mobileSearch">
        <PageHeaderIconButton
          onClick={(event) =>
            openGlobalSearch(
              event,
              `GSB Mobile - ${isHomePage ? 'home' : 'header'} search`,
            )
          }
        >
          <MagnifyingGlassIcon className="h-6 w-6" />
        </PageHeaderIconButton>
        <GlobalSearchModal
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          open={openGlobalSearchModal}
          handleClose={handleCloseGlobalSearch}
          isMobile={isMobile}
        />
      </div>
    )
  } else {
    return (
      <div
        className={
          isHomePage && isMobile
            ? 'w-full'
            : isHomePage
              ? 'w-[600px]'
              : 'w-[200px]'
        }
      >
        <Button
          startIcon={
            <>
              <MagnifyingGlassIcon className="w-5 h-5 text-gray-400 stroke-2" />
              {!isMobile && (
                <span className="ml-2 !text-sm text-gray-400">Search...</span>
              )}
            </>
          }
          endIcon={
            <>
              {!isMobile && (
                <span className="!text-xs text-gray-400 bg-gray-100 p-0.5 border rounded border-gray-200 ml-4">
                  {os === 'linux' ? 'Ctrl+M' : osSymbol + 'K'}
                </span>
              )}
            </>
          }
          fullWidth
          variant={isHomePage ? 'contained' : 'outlined'}
          onClick={(event) =>
            openGlobalSearch(
              event,
              `GSB - ${isHomePage ? 'home' : 'header'} search`,
            )
          }
          disableRipple
          className={buttonStyle}
        ></Button>
        <GlobalSearchModal
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          open={openGlobalSearchModal}
          handleClose={handleCloseGlobalSearch}
          isMobile={isMobile}
        />
      </div>
    )
  }
}

export default GlobalSearchButton
