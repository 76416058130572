import {
  NOTIFICATION_RESOURCE_ROUTE_TYPE,
  NOTIFICATION_RESOURCE_TYPE,
} from 'api/src/common/enums'
import { BaserowNotificationMetadata } from 'api/src/common/notificationsMetadataTypes'
import dayjs from 'dayjs'
import { isNil } from 'ramda'

import { routes } from '@redwoodjs/router'

import { SENTIMENT_ADMIN_TABS } from 'src/pages/SettingsSentimentAnalysisPage/types'

import { NotificationItemAPI, NotificationItemUI } from './types'

const getBaselinkRouteForNotification = (notification: NotificationItemUI) => {
  const metadata: BaserowNotificationMetadata = notification.metadata
  if (metadata.databaseId && metadata.tableId && metadata.recordId) {
    return routes.baseLinkToRecordWithoutView({
      baseId: metadata.databaseId,
      tableId: metadata.tableId,
      rowId: metadata.recordId,
    })
  } else if (metadata.databaseId && metadata.tableId) {
    return routes.baseLinkToTable({
      baseId: metadata.databaseId,
      tableId: metadata.tableId,
    })
  } else if (metadata.databaseId) {
    return routes.baseLinkToBase({ baseId: metadata.databaseId })
  } else {
    return null
  }
}

export const getRouteForNotification = (notification: NotificationItemUI) => {
  if (isNil(notification.resourceRouteType)) {
    return null
  }

  switch (notification.resourceRouteType) {
    case NOTIFICATION_RESOURCE_ROUTE_TYPE.SENTIMENT_ANALYSIS_ADMIN_FLAGS:
      return routes.settingsSentimentAnalysisTab({
        tab: SENTIMENT_ADMIN_TABS.FLAGGED.toLowerCase(),
      })
    case NOTIFICATION_RESOURCE_ROUTE_TYPE.SENTIMENT_ANALYSIS_MEMBER_PAGE:
      return routes.sentimentAnalysis()
    case NOTIFICATION_RESOURCE_ROUTE_TYPE.GOAL:
      return routes.improverGoalsEdit({ goalId: notification.resourceId })
    case NOTIFICATION_RESOURCE_ROUTE_TYPE.BASEROW:
      return getBaselinkRouteForNotification(notification)
  }
}

export const mapper = {
  toUI: (notifications: NotificationItemAPI): NotificationItemUI => {
    return {
      ...notifications,
      resourceType: notifications.resourceType as NOTIFICATION_RESOURCE_TYPE,
      resourceRouteType:
        notifications.resourceRouteType as NOTIFICATION_RESOURCE_ROUTE_TYPE,
      createdAt: dayjs(notifications.createdAt),
      readDate: notifications.readDate && dayjs(notifications.readDate),
      metadata: notifications.metadata as NotificationItemUI['metadata'],
    }
  },
  toAPI: (notification: NotificationItemUI): NotificationItemAPI => {
    return {
      ...notification,
      metadata: notification.metadata as NotificationItemAPI['metadata'],
      createdAt: notification.createdAt.toISOString(),
      readDate: notification.readDate.toISOString(),
    }
  },
}
