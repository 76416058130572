import { useContext, type FC, type ReactNode } from 'react'

import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { setTag } from '@sentry/browser'
import { CUSTOM_HTTP_HEADERS, isLocal } from 'api/src/common/enums'
import { MoonLoader } from 'react-spinners'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/dist/apollo'

import LiveFeatureProvider from 'src/lib/hooks/LiveFeatureFlag/LiveFeatureProvider'
import FatalErrorPage from 'src/pages/FatalErrorPage'

import { apolloCache } from './apolloCache'
import 'dayjs/locale/en-au'
import { AuthClientContext } from './App'
import { HubsAuthType } from './auth'
import RefreshClient from './components/RefreshClient/RefreshClient'
import { hubsFetch, getHubsLink } from './config'
import AuthLoaderProvider from './lib/hooks/AuthLoaderProvider'
import { ConfirmProvider } from './lib/hooks/Confirmation'

const webCommitHash = process.env.GIT_COMMIT_HASH
const webCommitTime = process.env.GIT_COMMIT_TIME

const locales = ['en-au'] as const

// If this has some value, we can enable it in all non-PROD environments
// It does increase the bundled size though
if (isLocal) {
  loadDevMessages()
  loadErrorMessages()
}

export let useAuth: HubsAuthType['useAuth']

const Providers: FC<{ browserTabId: string; children: ReactNode }> = ({
  browserTabId,
  children,
}) => {
  const [auth, _setAuth] = useContext(AuthClientContext)
  const { AuthProvider, useAuth: providerUseAuth, usedAuthClient } = auth
  useAuth = providerUseAuth

  setTag('authEmail', usedAuthClient?.email)
  setTag('browserTabId', browserTabId)

  return auth ? (
    <AuthProvider>
      <FatalErrorBoundary page={FatalErrorPage}>
        <AuthLoaderProvider>
          <RedwoodProvider titleTemplate="%AppTitle | %PageTitle">
            <RedwoodApolloProvider
              graphQLClientConfig={{
                link: getHubsLink(),
                httpLinkConfig: {
                  fetch: hubsFetch,
                  includeUnusedVariables: true,
                  headers: {
                    [CUSTOM_HTTP_HEADERS.GIT_COMMIT_HASH]: webCommitHash,
                    [CUSTOM_HTTP_HEADERS.GIT_COMMIT_TIME]: webCommitTime,
                    [CUSTOM_HTTP_HEADERS.AUTH_EMAIL]: usedAuthClient?.email,
                    [CUSTOM_HTTP_HEADERS.BROWSER_TAB_ID]: browserTabId,
                  },
                },
                cache: apolloCache,
              }}
              useAuth={useAuth}
            >
              <LiveFeatureProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={locales[0]}
                >
                  <ConfirmProvider>
                    <RefreshClient />
                    {children}
                  </ConfirmProvider>
                </LocalizationProvider>
              </LiveFeatureProvider>
            </RedwoodApolloProvider>
          </RedwoodProvider>
        </AuthLoaderProvider>
      </FatalErrorBoundary>
    </AuthProvider>
  ) : (
    <MoonLoader />
  )
}

export default Providers
