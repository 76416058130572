import { Context } from 'react'

import { captureEvent, captureException } from '@sentry/browser'
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationDetailsData,
  ISignUpResult,
} from 'amazon-cognito-identity-js'
import dayjs from 'dayjs'

import { AuthContextInterface, createAuthentication } from '@redwoodjs/auth'
import { AuthProviderProps } from '@redwoodjs/auth/dist/AuthProvider/AuthProvider'

import { LocalStorageKeys } from 'src/types/enums'

import { AuthClient, DefaultAuthClient } from './lib/authClients'

export interface ChangePasswordProps {
  oldPassword: string
  newPassword: string
}

export interface NewPasswordRequired {
  newPasswordCallback: (
    newPassword: string,
    /** user: Optional - if not provided, the function will use the current user */
    user?: CognitoUser,
  ) => Promise<CognitoUserSession>
  status: {
    code: number
    message: string
  }
}

export interface TotpCallback {
  totpCallback: (code: string) => Promise<CognitoUserSession>
}

type CognitoRedwoodAuthType = AuthContextInterface<
  CognitoUser,
  unknown,
  NewPasswordRequired | CognitoUserSession | TotpCallback | Error,
  unknown,
  void,
  unknown,
  Error | ISignUpResult,
  void | Error,
  unknown,
  unknown,
  unknown,
  RedwoodCognitoClient
>
export type StafflinkUseAuth = () => CognitoRedwoodAuthType
export interface RedwoodCognitoClient {
  client: CognitoUserPool
  login: (options: {
    email: string
    password?: string
    session?: CognitoUserSession
  }) => Promise<CognitoUserSession | NewPasswordRequired | TotpCallback | Error>
  currentUser: () => Promise<CognitoUser>
  /**
   * Use this function when a user has a reset token and needs to change their password
   */
  confirmNewPassword: (
    email: string,
    newPassword: string,
    token: string,
  ) => Promise<void | Error>
  logout: () => Promise<void>
  signup: (credentials: CognitoCredentials) => Promise<ISignUpResult | Error>
  getToken: () => Promise<string>
  getUserMetadata: () => Promise<CognitoUser>
  forgotPassword: (email: string) => Promise<void | Error>
  resetPassword: ({
    oldPassword,
    newPassword,
  }: ChangePasswordProps) => Promise<'SUCCESS' | Error>
}

export interface ValidateResetTokenResponse {
  error?: string
  [key: string]: string | undefined
}

interface CognitoCredentials {
  email: string
  password: string
}
// Replace this with the auth service provider client sdk
const customCognitoClient = (
  client: CognitoUserPool,
): RedwoodCognitoClient => ({
  client,
  login: ({ email, password, session }) => {
    localStorage.removeItem(LocalStorageKeys.USER_CLIENT_ID)

    return new Promise((resolve) => {
      if (session) {
        if (session.isValid()) {
          const user = new CognitoUser({
            Username: email,
            Pool: client,
          })
          user.setSignInUserSession(session)
          return resolve(session)
        }
      }
      const authenticationData: IAuthenticationDetailsData = {
        Username: email,
        Password: password,
      }
      const authenticationDetails = new AuthenticationDetails(
        authenticationData,
      )
      const userData = {
        Username: email,
        Pool: client,
      }
      const cognitoUser = new CognitoUser(userData)
      cognitoUser?.setAuthenticationFlowType('USER_PASSWORD_AUTH')
      cognitoUser?.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession) => {
          resolve(session)
        },
        onFailure: (err) => {
          const error = new Error(
            err?.message || 'Unknown error authenticating user',
          )
          captureEvent({
            message: 'Auth: authenticateUser: failed',
            level: 'warning',
            extra: { error },
          })
          resolve(error)
        },
        newPasswordRequired: (_userAttributes, _requiredAttributes) => {
          resolve({
            // TODO: Fix security vulnerability with allowing a CognitoUser Parameter to this callback
            newPasswordCallback: (newPassword: string, user = cognitoUser) => {
              return new Promise((resolve, reject) => {
                user.completeNewPasswordChallenge(
                  newPassword,
                  {},
                  {
                    onSuccess: (session: CognitoUserSession) => {
                      resolve(session)
                    },
                    onFailure: (err: Error | unknown) => {
                      reject(err)
                    },
                  },
                )
              })
            },
            status: {
              code: 409,
              message: 'User needs to change their password',
            },
          })
        },
        totpRequired: (
          challengeName: string,
          _challengeParameters: unknown,
        ) => {
          resolve({
            totpCallback: (totpCode: string, user = cognitoUser) => {
              return new Promise((resolve, reject) => {
                user.sendMFACode(
                  totpCode,
                  {
                    onSuccess: (session: CognitoUserSession) => {
                      resolve(session)
                    },
                    onFailure: (err: Error | unknown) => {
                      captureException(err, {
                        extra: {
                          message: 'Auth: sendMFACode: failed',
                        },
                      })
                      reject(err)
                    },
                  },
                  challengeName,
                )
              })
            },
          })
        },
      })
    })
  },
  logout: () => {
    localStorage.removeItem(LocalStorageKeys.USER_CLIENT_ID)
    return new Promise((resolve) => {
      const user = client.getCurrentUser()
      user?.getSession((err: Error, _session: CognitoUserSession) => {
        if (err) {
          captureException(err, {
            extra: {
              message: 'Auth: getSession: failed',
            },
          })
          resolve()
        }
        user.signOut(() => {
          resolve()
        })
      })
    })
  },
  signup: ({ email, password }: CognitoCredentials) => {
    return new Promise<ISignUpResult | Error>(function (resolve) {
      const attributeList = [
        new CognitoUserAttribute({
          Name: 'email',
          Value: email,
        }),
      ]

      client.signUp(
        email,
        password,
        attributeList,
        [],
        function (err: Error, res: ISignUpResult) {
          if (err) {
            captureException(err, {
              extra: {
                message: 'Auth: Cognito.signUp: failed',
              },
            })
            resolve(err)
          } else {
            resolve(res)
          }
        },
      )
    }).catch((err) => {
      captureException(err, {
        extra: {
          message: 'Auth: signUp: failed',
        },
      })
      return err
    })
  },
  getToken: (): Promise<string> => {
    return new Promise<string>((resolve) => {
      const user = client.getCurrentUser()
      user?.getSession((err: Error, session: CognitoUserSession) => {
        if (err) {
          captureException(err, {
            extra: {
              message: 'Auth: getSession: failed',
            },
          })
          resolve(null)
        } else {
          // Get current time in seconds
          const currentTime = Math.floor(Date.now() / 1000)
          // Get expiration time in seconds
          const expiration = session.getAccessToken().getExpiration()
          // Refresh the session if it's less than 5 minutes before expiry
          if (currentTime - 60 * 5 > expiration) {
            user.refreshSession(
              session.getRefreshToken(),
              (err, session: CognitoUserSession) => {
                if (err) {
                  captureException(err, {
                    extra: {
                      message: 'Auth: getRefreshToken: failed',
                    },
                  })
                  resolve(null)
                } else {
                  const jwtToken = session.getAccessToken().getJwtToken()
                  resolve(jwtToken)
                }
              },
            )
          } else {
            const jwtToken = session.getAccessToken().getJwtToken()
            resolve(jwtToken)
          }
        }
      })
    }).catch((err) => {
      captureException(err, {
        extra: {
          message: 'Auth: getToken: failed',
        },
      })
      return null
    })
  },
  getUserMetadata: (): Promise<CognitoUser> => {
    return new Promise<CognitoUser>((resolve) => {
      const currentUser = client.getCurrentUser()
      resolve(currentUser)
    }).catch((err) => {
      captureException(err, {
        extra: {
          message: 'Auth: getUserMetadata: failed',
        },
      })
      return null
    })
  },
  currentUser: () => {
    return new Promise<CognitoUser>((resolve) => {
      const user = client.getCurrentUser()
      user?.getSession((err: Error, _session: CognitoUserSession) => {
        if (err) {
          captureException(err, {
            extra: {
              message: 'Auth: getSession: failed',
            },
          })
          resolve(null)
        }
        resolve(user)
      })
    }).catch((err) => {
      captureException(err, {
        extra: {
          message: 'Auth: currentUser: failed',
        },
      })
      return null
    })
  },
  forgotPassword: (email: string) => {
    return new Promise((resolve) => {
      const user = new CognitoUser({
        Username: email,
        Pool: client,
      })
      user.forgotPassword({
        onSuccess: (result) => {
          resolve(result)
        },
        onFailure: (err: Error) => {
          captureException(err, {
            extra: {
              message: `Error getting validation token for user ${email}. ${err.message}`,
            },
          })
          resolve(Error(err.message))
        },
      })
    })
  },
  confirmNewPassword: (email, newPassword, token) => {
    return new Promise((resolve) => {
      const user = new CognitoUser({
        Username: email,
        Pool: client,
      })
      if (!user) {
        const error = Error('Could not find that user')
        captureException(error)
        return resolve(error)
      } else {
        user.confirmPassword(token, newPassword, {
          onSuccess: (_success: string) => {
            return resolve()
          },
          onFailure: (err: Error) => {
            captureException(err, {
              extra: { message: 'Auth: confirmPassword: failed' },
            })
            return resolve(err)
          },
        })
      }
    })
  },
  resetPassword: ({ oldPassword, newPassword }: ChangePasswordProps) => {
    return new Promise((resolve) => {
      const user = client.getCurrentUser()
      if (user) {
        user.changePassword(oldPassword, newPassword, (err, result) => {
          if (err || result !== 'SUCCESS') {
            const error = err || Error('Could not change password')
            captureException(err, {
              extra: { message: 'Auth: changePassword: failed' },
            })
            resolve(error)
          } else {
            resolve(result)
          }
        })
      } else {
        const notLoggedInError = Error('Not logged in')
        captureException(notLoggedInError)
        resolve(notLoggedInError)
      }
    })
  },
})

export function createCustomAuth(client: CognitoUserPool) {
  const authImplementation = createCustomAuthImplementation(client)

  // You can pass custom provider hooks here if you need to as a second
  // argument. See the Redwood framework source code for how that's used
  return createAuthentication(authImplementation)
}

function createCustomAuthImplementation(customClient: CognitoUserPool) {
  const cognitoClient = customCognitoClient(customClient)
  return {
    type: 'cognito',
    client: cognitoClient,
    login: cognitoClient.login,
    logout: cognitoClient.logout,
    signup: cognitoClient.signup,
    getToken: cognitoClient.getToken,
    getUserMetadata: cognitoClient.getUserMetadata,
    forgotPassword: cognitoClient.forgotPassword,
  }
}

export const createCognitoClient = (authClient: AuthClient) => {
  const cognitoClient = new CognitoUserPool(authClient.clientData)
  return { ...createCustomAuth(cognitoClient), usedAuthClient: authClient }
}
// Init auth from localstorage
export const initializeAuth = () => {
  let usedAuthClient: AuthClient

  const initialAuthClients: AuthClient[] = JSON.parse(
    localStorage.getItem('storedAuthClients') ||
      JSON.stringify([DefaultAuthClient]),
  )

  // Old authClient, that we need to restore
  const paramAuthClient: AuthClient = JSON.parse(
    localStorage.getItem('paramAuthClient') || '{}',
  )
  const existingParamAuthClient = initialAuthClients.find(
    (e) => e.id === paramAuthClient?.id,
  )
  // If initialAuthClients doesn't have the paramAuthClient, add it
  if (!existingParamAuthClient && paramAuthClient?.id) {
    initialAuthClients.push({
      ...paramAuthClient,
    })
  }

  // Logic below is to find the latest of lastLogged in client, or lastSwitchedTo client
  // This is because certain logic in our app switches the authclient to the default one
  // Such as visiting the password reset page, or the reset code page, and we want to persist that
  const lastSwitchedToAuthClient = initialAuthClients
    .concat()
    .sort(
      (a, b) =>
        dayjs(b.lastSwitchedTo || '1970-01-01').unix() -
        dayjs(a.lastSwitchedTo || '1970-01-01').unix(),
    )[0]
  const lastSwitchedToDate = dayjs(
    lastSwitchedToAuthClient?.lastSwitchedTo || '1970-01-01',
  )
  const lastLoggedInAuthClient = initialAuthClients
    .concat()
    .sort(
      (a, b) =>
        dayjs(b.lastLogin || '1970-01-01').unix() -
        dayjs(a.lastLogin || '1970-01-01').unix(),
    )[0]
  const lastLoggedInDate = dayjs(
    lastLoggedInAuthClient?.lastLogin || '1970-01-01',
  )

  if (lastSwitchedToDate.isAfter(lastLoggedInDate)) {
    usedAuthClient = lastSwitchedToAuthClient
  } else {
    usedAuthClient = lastLoggedInAuthClient
  }

  return createCognitoClient(usedAuthClient)
}

export let initialAuth = initializeAuth()

// Use this to set the auth client from within the app, and update it when
export const resetAuth = (authClient: AuthClient) => {
  if (authClient.id === initialAuth.usedAuthClient.id) return initialAuth
  const storedJson = localStorage.getItem('storedAuthClients')
  let authClients: AuthClient[] = storedJson ? JSON.parse(storedJson) : []

  const existingAuthClient = authClients.find(
    (client) =>
      client.id === authClient.id && client.email === authClient.email,
  )

  if (existingAuthClient) {
    existingAuthClient.lastSwitchedTo = new Date().toISOString()
    authClients = authClients.map((client) =>
      client.id === existingAuthClient.id &&
      client.email === existingAuthClient.email
        ? existingAuthClient
        : client,
    )
  } else {
    authClients.push({
      ...authClient,
      lastSwitchedTo: new Date().toISOString(),
    })
  }
  localStorage.setItem('storedAuthClients', JSON.stringify(authClients))
  const refreshedAuth = createCognitoClient(authClient)
  initialAuth = refreshedAuth
  return refreshedAuth
}

export type HubsAuthType = {
  usedAuthClient: AuthClient
  AuthContext: Context<CognitoRedwoodAuthType>
  AuthProvider: (props: AuthProviderProps) => JSX.Element
  useAuth: () => CognitoRedwoodAuthType
}
