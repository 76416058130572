import React, { FC, useEffect, useState } from 'react'

import {
  AcademicCapIcon,
  BookOpenIcon,
  LinkIcon,
  MapIcon,
  Squares2X2Icon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { Box } from '@mui/material'
import { SEARCH_RESULT_TYPE } from 'api/src/common/enums'

import Loading from 'src/components/Library/Loading'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import Tab from '../Library/Tab/Tab'
import Tabs from '../Library/Tabs/Tabs'

import GoogleSearchResultList from './GoogleSearchResultList'

interface GlobalSearchTabsProps {
  isMobile: boolean
  tabVal: string
  setTabVal: (val: string) => void
}

const iconStyle = 'w-4 h-4 text-gray-400'

export const categories = [
  {
    icon: <LinkIcon className={iconStyle} />,
    name: 'Quick Links',
    value: SEARCH_RESULT_TYPE.HOME_LINKS,
  },
  {
    icon: <UserIcon className={iconStyle} />,
    name: 'People',
    value: SEARCH_RESULT_TYPE.MEMBERSHIP,
  },
  {
    icon: <AcademicCapIcon className={iconStyle} />,
    name: 'Courses',
    value: SEARCH_RESULT_TYPE.COURSE,
  },
  {
    icon: <MapIcon className={iconStyle} />,
    name: 'Process Maps',
    value: SEARCH_RESULT_TYPE.PROCESS_MAP,
  },
  {
    icon: <BookOpenIcon className={iconStyle} />,
    name: 'Knowledge Base',
    value: SEARCH_RESULT_TYPE.KNOWLEDGE_BASE,
  },
  {
    icon: <Squares2X2Icon className={iconStyle} />,
    name: 'Workflows',
    value: SEARCH_RESULT_TYPE.HUBDASHLAYOUT,
  },
  // Add more categories as needed
]

export const GoogleSuggestionsContent = ({ query }) => {
  const [googleSuggestions, setGoogleSuggestions] = useState([])
  const [isLoading, setLoading] = useState(false)
  const handleGoogleSearch = () => {
    if (query !== '') {
      const script = document.createElement('script')
      script.src = `https://suggestqueries.google.com/complete/search?client=firefox&q=${query}&jsonp=autocompleteCallback`
      document.body.appendChild(script)
    } else {
      setGoogleSuggestions([])
    }
  }

  const clearGoogleSearch = () => {
    setTimeout(() => {
      setGoogleSuggestions([])
    }, 150)
  }

  const autoCompleteCallback = (response) => {
    const [, suggestions] = response
    setGoogleSuggestions(suggestions)
  }

  window.autocompleteCallback = autoCompleteCallback

  useEffect(() => {
    if (query === '') {
      clearGoogleSearch()
      return // Early return to avoid unnecessary async code execution
    }

    ;(async () => {
      await handleGoogleSearch()
      setLoading(false)
    })()
  }, [query])

  if (query === '' || !query) {
    return null
  }

  return (
    <>
      <div className="flex flex-row items-center pb-2 pt-1 mt-2 justify-between">
        <h6 className="uppercase text-gray-400 text-sm leading-4 font-medium tracking-wider">
          Google
        </h6>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <GoogleSearchResultList
          query={query}
          googleSuggestions={googleSuggestions}
        />
      )}
    </>
  )
}

export const GlobalSearchTabs: FC<GlobalSearchTabsProps> = ({
  isMobile,
  tabVal,
  setTabVal,
}) => {
  const { trackEvent } = useAnalytics()

  return (
    <div className="w-full">
      {!isMobile && (
        <Box>
          <Tabs
            value={tabVal}
            onChange={(_, newValue) => {
              setTabVal(newValue as string)
              trackEvent('Search', 'GS change tabs', {
                newTab: newValue,
              })
            }}
          >
            <Tab label="All" value="all" />
            {categories.map((category) => (
              <Tab
                key={category.value}
                label={category.name}
                value={category.value}
              />
            ))}
          </Tabs>
        </Box>
      )}
    </div>
  )
}
