import React, { useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/20/solid'
import {
  CloudArrowUpIcon,
  EyeIcon,
  MinusCircleIcon,
} from '@heroicons/react/24/outline'
import { Grid, Stack, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  BASEROW_IMPORT_MATCH_TYPE,
  DetailedTableRecord,
  DetailedViewRecord,
  MatchingBaserowViewType,
} from 'api/src/common/baserowImporterTypes'
import Select from 'react-select'

import { SelectOptionType } from 'src/components/BaserowImporter/BaserowImporterComponentFieldItem'
import BaserowImporterModalViewDetails from 'src/components/BaserowImporter/BaserowImporterModalViewDetails'
import { getItemMatchStatus } from 'src/components/BaserowImporter/helpers'
import Button from 'src/components/Library/Button/Button'

interface BaserowImporterFViewComponentProps {
  viewItem: DetailedViewRecord
  unmatchedBaserowViews: MatchingBaserowViewType[]
  handleViewMatch: (airtableViewId: number, baserowViewId: number) => void
  deleteViewMatch: (airtableViewId: number, baserowViewId: number) => void
  handleViewCreate: (handleViewCreate: number) => void
  handleViewIgnore: (viewId: number) => void
  tableItem: DetailedTableRecord
}
const BaserowImporterComponentViewItem = ({
  viewItem,
  unmatchedBaserowViews,
  handleViewMatch,
  deleteViewMatch,
  handleViewCreate,
  handleViewIgnore,
  tableItem,
}: BaserowImporterFViewComponentProps) => {
  const [options, setOptions] = useState<SelectOptionType[]>([])
  const [selectedRecord, setSelectedRecord] = useState<DetailedViewRecord>(null)
  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    setOptions(
      unmatchedBaserowViews.map((view) => ({
        value: view.id,
        label: view.name,
      })),
    )
  }, [unmatchedBaserowViews, viewItem])

  return (
    <>
      <Grid item xs={5}>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {getItemMatchStatus(viewItem, 'View')}

          <Typography className={'text-xs truncate'}>
            {viewItem.name}
          </Typography>
          <Tooltip title={'View Details'}>
            <div>
              <Button
                fullWidth={false}
                variant="text"
                className="min-w-0 rounded-full"
                onClick={() => {
                  setSelectedRecord(viewItem)
                  setOpenModal(true)
                }}
              >
                <EyeIcon
                  className={`w-5 h-5
                    text-blue-600
                  `}
                />
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Grid>
      <Grid item xs={5}>
        {viewItem.matchingView ? (
          viewItem.matchingView.matchType ===
          BASEROW_IMPORT_MATCH_TYPE.IGNORE ? (
            <Typography className={'capitalize text-xs truncate'}>
              View will be ignored
            </Typography>
          ) : [
              BASEROW_IMPORT_MATCH_TYPE.MANUAL,
              BASEROW_IMPORT_MATCH_TYPE.DETECTED,
            ].includes(viewItem.matchingView.matchType) ? (
            <Typography className={'capitalize text-xs truncate'}>
              {`${viewItem.matchingView.name} - ID: ${viewItem.matchingView.id}`}
            </Typography>
          ) : (
            <Typography className={'capitalize text-xs truncate'}>
              New View Will Be Created
            </Typography>
          )
        ) : (
          <Select
            options={options}
            onChange={(selectedOption) => {
              handleViewMatch(viewItem.id, selectedOption.value)
            }}
            className={'text-xs w-full'}
          />
        )}
      </Grid>

      <Grid item xs={2}>
        <Stack direction={'row'} className={'w-full'} alignItems={'center'}>
          <Tooltip title={'Create New View'}>
            <div>
              <Button
                disabled={viewItem.matchingView?.matchType === null}
                fullWidth={false}
                variant="text"
                className="min-w-0 rounded-full"
                onClick={() => handleViewCreate(viewItem.id)}
              >
                <CloudArrowUpIcon
                  className={`w-5 h-5 ${
                    viewItem.matchingView ? 'text-gray-300' : 'text-blue-600'
                  }`}
                />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={'Ignore View'}>
            <div>
              <Button
                fullWidth={false}
                disabled={viewItem.matchingView?.matchType === null}
                variant="text"
                className="min-w-0 rounded-full"
                onClick={() => handleViewIgnore(viewItem.id)}
              >
                <MinusCircleIcon
                  className={`w-5 h-5 ${
                    viewItem.matchingView ? 'text-gray-300' : 'text-orange-600'
                  }`}
                />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={'Delete Match'}>
            <div>
              <Button
                fullWidth={false}
                disabled={!viewItem.matchingView?.matchType}
                variant="text"
                className="min-w-0 rounded-full"
                onClick={() => deleteViewMatch(viewItem.id, viewItem.id)}
              >
                <XMarkIcon
                  className={`w-5 h-5 ${
                    viewItem.matchingView ? 'text-red-600' : 'text-gray-300'
                  }`}
                />
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Grid>
      {selectedRecord && (
        <BaserowImporterModalViewDetails
          selectedRecord={selectedRecord}
          tableItem={tableItem}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  )
}

export default BaserowImporterComponentViewItem
