import React, { useEffect, useMemo, useState } from 'react'

import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { Box, Grid, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DetailedTableRecord,
  DetailedViewRecord,
  MatchingBaserowViewType,
} from 'api/src/common/baserowImporterTypes'

import BaserowImporterComponentViewItem from 'src/components/BaserowImporter/BaserowImporterComponentViewItem'

interface BaserowImporterTableComponentProps {
  tableItem: DetailedTableRecord
  handleViewMatch: (airtableViewId: number, baserowViewId: number) => void
  deleteViewMatch: (deleteViewMatch: number) => void
  handleViewCreate: (handleViewCreate: number) => void
  handleViewCreateAll: (tableId: number[]) => void
  handleViewIgnore: (viewId: number) => void
}

const BaserowImporterComponentViewList = ({
  tableItem,
  handleViewMatch,
  deleteViewMatch,
  handleViewCreate,
  handleViewCreateAll,
  handleViewIgnore,
}: BaserowImporterTableComponentProps) => {
  const [unmatchedBaserowViews, setUnmatchedBaserowViews] = useState<
    MatchingBaserowViewType[]
  >([])

  useEffect(() => {
    if (!tableItem.matchingTable) {
      return
    }
    if (!tableItem.matchingTable?.views) {
      return
    }
    const unmatchedViews: MatchingBaserowViewType[] = []
    for (const brView of tableItem.matchingTable.views) {
      let matchFound = false
      for (const airView of tableItem.views) {
        if (airView.matchingView && airView.matchingView.id === brView.id) {
          matchFound = true
          break
        }
      }
      if (!matchFound) {
        unmatchedViews.push(brView)
      }
    }
    setUnmatchedBaserowViews(unmatchedViews)
  }, [tableItem])

  const sortedViews: DetailedViewRecord[] = useMemo(() => {
    const sortedData: DetailedViewRecord[] = (
      tableItem.views as DetailedViewRecord[]
    )
      ?.slice()
      .sort((a, b) => a.name.localeCompare(b.name))
    return sortedData
  }, [tableItem])

  return (
    <Box className={'bg-white p-5 border-t-2'}>
      {tableItem.matchingTable && (
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography fontWeight={'bold'} className={'text-xs'}>
              View Name
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography fontWeight={'bold'} className={'text-xs'}>
              Matching View
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={0} alignItems={'center'}>
              <Typography fontWeight={'bold'} className={'text-xs'}>
                Actions
              </Typography>

              <Tooltip title={'Create all views'}>
                <IconButton
                  onClick={() => {
                    handleViewCreateAll(tableItem.views.map((view) => view.id))
                  }}
                >
                  <CloudArrowUpIcon className={'w-5 h-5'} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {sortedViews.map((view) => (
            <BaserowImporterComponentViewItem
              key={view.id}
              viewItem={view}
              handleViewMatch={handleViewMatch}
              deleteViewMatch={deleteViewMatch}
              unmatchedBaserowViews={unmatchedBaserowViews}
              handleViewCreate={handleViewCreate}
              handleViewIgnore={handleViewIgnore}
              tableItem={tableItem}
            />
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default BaserowImporterComponentViewList
