export enum AUTHCLIENTS {
  DEFAULT = 'default',
  AZURE_INOVAYT = 'azure_inovayt',
}

export interface AuthClient {
  id: AUTHCLIENTS | string
  clientData: {
    UserPoolId: string
    ClientId: string
  }
  identityProvider?: string
  loginUrl?: string
  name?: string
  federated?: boolean
  tokenUrl?: string
  email?: string
  lastLogin?: string // ISO Date string
  createdTimestamp?: string // ISO Date string
  lastSwitchedTo?: string // ISO Date string
}
export interface GetLoginUrlInput {
  identityProvider?: string
  clientId?: string
}

const hostname = () =>
  window.location.hostname === 'localhost'
    ? 'http://localhost:8910'
    : window.location.origin
export const getLoginRedirectUri = () => hostname() + '/app/login'
export const getLogoutRedirectUri = () => hostname() + '/app/logout'

export const getLoginUrl = (input: GetLoginUrlInput) =>
  `https://${process.env.COGNITO_DOMAIN}.auth.${
    process.env.AWS_REGION
  }.amazoncognito.com/oauth2/authorize?identity_provider=${
    input.identityProvider
  }&redirect_uri=${getLoginRedirectUri()}&response_type=CODE&client_id=${
    input.clientId
  }&scope=email%20openid&prompt=login`
export const getLogoutUrl = (clientId: string) =>
  `https://${process.env.COGNITO_DOMAIN}.auth.${
    process.env.AWS_REGION
  }.amazoncognito.com/logout?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    getLoginRedirectUri(),
  )}&response_type=code`
export const getTokenUrl = () =>
  `https://${process.env.COGNITO_DOMAIN}.auth.${process.env.AWS_REGION}.amazoncognito.com/oauth2/token`

export const DefaultAuthClient: AuthClient = {
  id: AUTHCLIENTS.DEFAULT,
  clientData: {
    UserPoolId: process.env.COGNITO_POOL_ID,
    ClientId: process.env.COGNITO_APP_CLIENT_ID,
  },
}
