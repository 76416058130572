import { TypePolicies } from '@apollo/client'

import { defaultMergePolicy } from './policies/defaultMergePolicy'

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      learnerCategoriesByClientAsAdmin: defaultMergePolicy,
      improverGoalMembersAll: defaultMergePolicy,
    },
  },
  ImproverGoalMember: {
    fields: {
      childGoals: defaultMergePolicy,
    },
  },
}
