import { styled } from '@mui/material/styles'
import Tab, { TabProps } from '@mui/material/Tab'

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&.Mui-selected': {
      color: '#6366f1',
    },
  }),
)

export default StyledTab
