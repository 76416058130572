import React from 'react'

import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

import { ColourReturnType } from 'src/components/Revenue/Profiles/Utils'

import LeadComponent from './LeadComponent'

export type LeadStatusType = {
  status: string
  color: ColourReturnType
  url: string
}

interface FormProps {
  profileName: string
  onClickFunction: () => void
  ccLeads?: LeadStatusType[]
  relloLeads?: LeadStatusType[]
  onCoverLeads?: LeadStatusType[]
  innovaytLeads?: LeadStatusType[]
}

const RecordComponent: React.FC<FormProps> = ({
  profileName,
  onClickFunction,
  ccLeads = [],
  relloLeads = [],
  innovaytLeads = [],
  onCoverLeads = [],
}) => {
  const leadsData = [
    { leadStatuses: ccLeads, key: 'ccLeads' },
    { leadStatuses: relloLeads, key: 'relloLeads' },
    { leadStatuses: onCoverLeads, key: 'onCoverLeads' },
    { leadStatuses: innovaytLeads, key: 'innovaytLeads' },
  ]

  return (
    <Grid container spacing={1} alignItems="center" className="border-b py-2">
      <Grid item xs={4} sm={4}>
        <Typography className={'capitalize'}>{profileName}</Typography>
      </Grid>
      {leadsData.map((lead) => (
        <Grid key={lead.key} item xs={2} sm={2}>
          <LeadComponent
            onClickFunction={onClickFunction}
            leadStatuses={lead.leadStatuses}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default RecordComponent
