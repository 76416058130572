import * as React from 'react'

import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import RevenueProfilesCell from 'src/components/Revenue/Profiles/RevenueProfilesCell'

const RevenueProfilesPage = () => {
  return (
    <>
      <Metadata title="Revenue Profiles" description="Revenue Profiles" />
      <div className={' h-screen'}>
        <PageHeader
          title={'Revenue Profiles'}
          parentDataTestId="ad-gen-page-header"
        />

        <RevenueProfilesCell />
      </div>
    </>
  )
}

export default RevenueProfilesPage
