import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import humanize from 'humanize-string'
import {
  FindProcessMapsEditorQuery,
  FindReactMapsSidePanelQuery,
} from 'types/graphql'

import { CourseLearnerCategory } from './components/Learner/Courses/CourseCategoriesCell'
import { CourseSummaryLearnerCategory } from './components/Learner/Courses/UserCoursesSummaryCell'
import { ReactMapCategory } from './components/ReactMap/ReactMapSidePanelCell'
import { LearnerLearnerCategory } from './components/Settings/LearnerCategory/LearnerCategoriesCell'
import { OS_OPTIONS } from './lib/hooks/UseOS'

dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
export const filterArray = <T,>(arr1: T[], arr2: T[]) => {
  const filtered = arr1.filter((el) => {
    return arr2.indexOf(el) === -1
  })
  return filtered
}

export const reorderItems = <T,>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const getDraggableItemStyle = (style) => {
  if (style.transform) {
    const axisLockY =
      'translate(0px' +
      style.transform.slice(
        style.transform.indexOf(','),
        style.transform.length,
      )
    return {
      ...style,
      transform: axisLockY,
    }
  }
  return style
}

export const filterProcessMaps = (
  processMaps: FindProcessMapsEditorQuery['mapCategoryList'],
  searchVal: string,
) => {
  const processMapsAll: FindProcessMapsEditorQuery['mapCategoryList'] =
    JSON.parse(JSON.stringify(processMaps))

  const processMapKeyWords: string[] = []

  for (const processMap of processMaps) {
    for (const map of processMap.mapperMap) {
      const processMapKeyword = processMap.name + ' ' + map.name
      processMapKeyWords[map.id] = processMapKeyword
    }
  }

  if (!searchVal) {
    processMaps = processMapsAll
  } else {
    return processMapsAll.filter(
      (processMap) =>
        processMap.name
          .toLowerCase()
          .includes(searchVal.trim().toLowerCase()) ||
        (() => {
          const processMapItems = processMap.mapperMap.filter((map) =>
            processMapKeyWords[map.id]
              .toLowerCase()
              .includes(searchVal.trim().toLowerCase()),
          )
          if (processMapItems.length > 0) {
            processMap.mapperMap = processMapItems
            return true
          }
          return false
        })(),
    )
  }
  return processMaps
}

export const filterReactMaps = (
  categoryList: FindReactMapsSidePanelQuery['mapCategoryList'],
  searchVal: string,
): ReactMapCategory[] => {
  const categoryListAll = JSON.parse(JSON.stringify(categoryList))
  const processMapKeyWords: string[] = []

  // Build the keywords list
  for (const processMap of categoryList) {
    for (const map of processMap.reactMaps) {
      processMapKeyWords[map.id] = processMap.name + ' ' + map.name
    }
  }

  if (!searchVal) {
    return categoryListAll
  } else {
    const filteredCategories = categoryListAll.filter(
      (category) =>
        category.name.toLowerCase().includes(searchVal.trim().toLowerCase()) ||
        category.reactMaps.some((map) =>
          processMapKeyWords[map.id]
            .toLowerCase()
            .includes(searchVal.trim().toLowerCase()),
        ),
    )

    return filteredCategories.map((category) => {
      return {
        ...category,
        reactMap: category.reactMaps.filter((map) =>
          processMapKeyWords[map.id]
            .toLowerCase()
            .includes(searchVal.trim().toLowerCase()),
        ),
      }
    })
  }
}

type GenericLearnerCategory =
  | CourseSummaryLearnerCategory
  | CourseLearnerCategory
  | LearnerLearnerCategory
export const filterCourses = <T extends GenericLearnerCategory>(
  learnerCategories: T[],
  searchVal?: string,
): T[] => {
  const learnerCategoriesAll = JSON.parse(JSON.stringify(learnerCategories))
  const courseKeyWords = []

  for (const category of learnerCategories) {
    for (const course of category.learnerCourses) {
      let courseKeywords = category.name + ' ' + course.name

      for (const activity of course.learnerActivities) {
        courseKeywords += ' ' + activity.name

        for (const task of activity.learnerTasks) {
          courseKeywords += ' ' + task.name
        }
      }
      courseKeyWords[course.id] = courseKeywords
    }
  }

  if (!searchVal) {
    learnerCategories = learnerCategoriesAll
  } else {
    learnerCategories = learnerCategoriesAll.filter(
      (category) =>
        category.name.toLowerCase().includes(searchVal.trim().toLowerCase()) ||
        (() => {
          const courses = category.learnerCourses.filter((course) =>
            courseKeyWords[course.id]
              .toLowerCase()
              .includes(searchVal.trim().toLowerCase()),
          )
          if (courses.length > 0) {
            category.learnerCourses = courses
            return true
          }
          return false
        })(),
    )
  }
  return learnerCategories
}

export const nextRank = (learnerItems: { rank: number }[]) => {
  const lastItem = learnerItems[learnerItems?.length - 1]
  return lastItem ? lastItem.rank + 1 : 1
}

export const truncate = (
  text: string,
  maxLength = 40,
  hideEllipsis = false,
) => {
  let output = text
  if (text && text.length > maxLength) {
    output = output.substring(0, maxLength)
    output = !hideEllipsis ? output + '...' : output
  }

  return output
}

export const timeTag = (datetime: string) => {
  return (
    datetime && (
      <time dateTime={datetime} title={datetime}>
        {new Date(datetime).toLocaleString()}
      </time>
    )
  )
}

export const formatEnum = (values) => {
  if (values) {
    if (Array.isArray(values)) {
      const humanizedValues = values.map((value) => humanize(value))
      return humanizedValues.join(', ')
    } else {
      return humanize(values)
    }
  }
}

export const activeStatusIcon = (checked: boolean): JSX.Element => {
  return checked ? (
    <CheckCircleIcon className={' h-6 w-6 mx-auto text-green-600'} />
  ) : (
    <XCircleIcon className={' h-6 w-6 mx-auto text-gray-400 '} />
  )
}

export const published = (item: { status: string }) => {
  return item?.status === 'PUBLISHED'
}

export const classNames = (...classes: unknown[]) => {
  return classes.filter(Boolean).join(' ')
}

export const getLatestContent = <
  T extends { type?: string; updatedAt?: string },
>(
  items: T[],
  amount: number,
) => {
  const sortedItems = [...items]
    .filter((item) => item.type !== 'CATEGORY')
    .sort(function (a, b) {
      const aTime = new Date(a.updatedAt)
      const bTime = new Date(b.updatedAt)

      return bTime.getTime() - aTime.getTime()
    })
    .slice(0, amount)
  return sortedItems
}

export const getNewestCreatedContent = <
  T extends { type?: string; createdAt?: string },
>(
  items: T[],
  amount: number,
) => {
  const sortedItems = [...items]
    .filter((item) => item.type !== 'CATEGORY')
    .sort(function (a, b) {
      const aTime = new Date(a.createdAt)
      const bTime = new Date(b.createdAt)

      return bTime.getTime() - aTime.getTime()
    })
    .slice(0, amount)
  return sortedItems
}

export const stringToColor = (string: string) => {
  let hash = 0

  /* eslint-disable no-bitwise */
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const idToColor = (id: number) => {
  const PHI = (1 + Math.sqrt(5)) / 2
  const goldenRatio = id * PHI - Math.floor(id * PHI)
  const hue = Math.floor(goldenRatio * 256)
  return `hsl(${hue}, 100%, 50%, 1)`
}

export const idToCuratedColor = (id: number) => {
  const colors = [
    '#1795A8',
    '#24afc1',
    '#fccf47',
    '#9652c2',
    '#f8c3b9',
    '#5f50A9',
    '#aed2f9',
    '#ecc1a1',
    '#3d7c98',
    '#73b0cd',
    '#a8dff1',
    '#b0e1a2',
    '#ffa1b3',
    '#73bff3',
    '#f3e4c7',
    '#5d7723',
    '#b3a943',
    '#faca72',
    '#006bb9',
    '#30a0e0',
    '#ffc973',
    '#e84476',
    '#cd184f',
    '#a2d5ac',
    '#3AADA8',
    '#557c83',
    '#ee534f',
    '#ff9201',
    '#ff6d00',
    '#505581',
  ]
  const PHI = (1 + Math.sqrt(5)) / 2
  const goldenRatio = id * PHI - Math.floor(id * PHI)

  const selection = Math.round(Math.floor(goldenRatio * 240) / 8)
  return '#5452ab'
  return colors[selection]
}

export const stringAvatar = (name: string, id?: number | undefined) => {
  name = name.trim()
  let color = ''
  let stringInitials = ''
  if (/\s/g.test(name)) {
    stringInitials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  } else {
    stringInitials = `${name.split(' ')[0][0]}`
  }

  if (id) {
    color = idToCuratedColor(id)
  } else {
    color = stringToColor(name)
  }

  return {
    sx: {
      bgcolor: color,
    },
    children: stringInitials,
  }
}

export const checkUrl = (url: string): string | undefined => {
  if (url) {
    switch (true) {
      case url.includes('https://'):
        return url
      case url.includes('mailto:'):
        return url
      case url.includes('www.'):
        return `https://${url}`
      case url.includes('.com'):
        return `https://www.${url}`
      default:
        return url
    }
  }
  return undefined
}

/** If we are not in production */
export const isDebug = process.env.ENVIRONMENT !== 'production'

type ItemWithId = { id: number }

/**
 * Insert item at index in array
 */
export const replaceAtAsNew = (
  array: ItemWithId[],
  index: number,
  item: ItemWithId,
): ItemWithId[] => [...array.slice(0, index), item, ...array.slice(index + 1)]

/**
 * Update or insert item in local list, useful for updating an item state in a list optimistically before the server responds
 */
export const updateItemInLocalList = <T extends ItemWithId>(
  currentArray: T[],
  id: number,
  input: Partial<T>,
) => {
  const updateItem = currentArray.find((item) => item.id === id)

  if (updateItem) {
    const itemIndex = currentArray.findIndex((item) => item.id === id)
    const updatedItem = { ...updateItem, ...input }
    const newTempList = replaceAtAsNew(currentArray, itemIndex, updatedItem)
    return newTempList as T[]
  } else {
    return [{ id: id, ...input }, ...currentArray] as T[]
  }
}

/**
 * Calculate time between two dates and return humanized string
 */
export const calculateTimeBetween = (
  startDate: Date | string,
  endDate: Date | string,
  humanize = true,
) => {
  // Init Dates
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  // Calculate Diff
  let duration = 0

  if (start.isSameOrBefore(end, 'd')) {
    duration = Math.abs(start.diff(end, 'd'))
  } else {
    duration = -Math.abs(start.diff(end, 'd'))
  }

  // Humanize it
  const humanizedDuration = dayjs.duration(Math.abs(duration), 'd').humanize()

  return humanize ? humanizedDuration : duration.toString()
}

/**
 * Calculate time since specified date and return humanized string
 */
export const calculateTimeSince = (date: Date | string) => {
  // Init Dates
  const pastDate = dayjs(date)
  const elapsed = dayjs().diff(pastDate, 'day')

  return dayjs().isSame(pastDate, 'day')
    ? 'Today'
    : dayjs.duration(-Math.abs(elapsed), 'days').humanize(true)
}

/**
 *  Get the placeholder text for a given input type
 */

export const getPlaceholderText = (type: string) => {
  switch (type) {
    case 'MapperCategory':
      return 'category'
    case 'MapperMap':
      return 'process map'
    default:
      return 'Record'
  }
}

dayjs.extend(quarterOfYear)

export const generateDayLabels = () => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const currentDate = new Date()
  const currentDayIndex = currentDate.getDay() // Get the index of the current day (0 for Sunday, 1 for Monday, etc.)

  // Create a new array starting with the current day
  const arrangedDaysOfWeek = [
    ...daysOfWeek.slice(currentDayIndex), // Days from current day to Sunday
    ...daysOfWeek.slice(0, currentDayIndex), // Days from Monday to the day before the current day
  ]

  return arrangedDaysOfWeek
}

export const generateMonthLabels = () => {
  const today = new Date()
  const twelveMonthsAgo = new Date(
    today.getFullYear() - 1,
    today.getMonth() + 1,
    1,
    0,
    0,
    0,
  )
  const labels = []
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  const startYear = twelveMonthsAgo.getFullYear()
  const startMonth = twelveMonthsAgo.getMonth()

  for (let year = startYear; year <= currentYear; year++) {
    const startMonthOfYear = year === startYear ? startMonth : 0
    const endMonthOfYear = year === currentYear ? currentMonth : 11

    for (let month = startMonthOfYear; month <= endMonthOfYear; month++) {
      const date = new Date(year, month, 1)
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
      }).format(date)

      labels.push(formattedDate)
    }
  }

  return labels
}

export const generateQuarterLabels = () => {
  const currentDate = new Date()
  const quarters = []

  for (let i = 0; i < 4; i++) {
    const year = currentDate.getFullYear()
    const quarter = Math.floor(currentDate.getMonth() / 3) + 1
    quarters.push(`Q${quarter} ${year}`)
    currentDate.setMonth(currentDate.getMonth() - 3)
  }

  quarters.reverse() // Reverse the order to get the oldest quarter first
  return quarters
}

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const convertNumberToShortForm = (number) => {
  if (number === 0) {
    return 0
  }

  const suffixes = ['', 'k', 'm', 'b', 't']
  const suffixGroups = Math.floor(Math.log10(Math.abs(number)) / 3)

  if (suffixGroups >= suffixes.length) {
    return number.toString() // Number is too large to be converted
  }
  const shortNumber = number / Math.pow(1000, suffixGroups)

  if (Number.isInteger(shortNumber)) {
    return shortNumber.toFixed(0) + suffixes[suffixGroups]
  } else {
    return shortNumber.toFixed(2).replace(/\.?0+$/, '') + suffixes[suffixGroups]
  }
}

export const countProperty = (array, propertyName) => {
  let total = 0

  for (const item of array) {
    total += item[propertyName]
  }

  return total
}

// Function to convert date to 'DD/MM/YYYY'
export const formatUpdatedAtDate = (updatedAtStr) => {
  const date = dayjs(updatedAtStr)
  return date.format('DD/MM/YYYY')
}

export const updateMap = (key, value, updateState, currentState) => {
  updateState(new Map(currentState.set(key, value)))
}

export const handleHotkey = (event, hotkey, callback, os) => {
  if (os === OS_OPTIONS.MACOS && event.metaKey && event.key === hotkey) {
    event.preventDefault()
    callback()
  } else if (
    os === OS_OPTIONS.WINDOWS &&
    event.ctrlKey &&
    event.key === hotkey
  ) {
    event.preventDefault()
    callback()
  }
}

export const formatCurrency = (
  number: number,
  locale: string,
  currency: string,
): string => {
  const validCurrencies = ['AUD'] // Add more currencies as needed

  if (!validCurrencies.includes(currency)) {
    throw new Error(`Invalid currency: ${currency}`)
  }
  const formatConfig: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currency || 'AUD',
    minimumFractionDigits: 2,
  }

  return new Intl.NumberFormat(locale, formatConfig).format(number)
}
