import React, { useEffect, useState } from 'react'

import Tab from 'src/components/Library/Tab/Tab'
import Tabs from 'src/components/Library/Tabs/Tabs'
import RecordComponentList from 'src/components/Revenue/Profiles/RecordComponentList'
import {
  ApplicationsType,
  ArrearsType,
  BuildingsType,
  CellDetailProperty,
  ContractsType,
  OffersType,
} from 'src/components/Revenue/Profiles/RevenueProfilesDetailsCell/RevenueProfilesDetailsCell'

interface FormProps {
  extSingletonProperty: CellDetailProperty
}

const RevenueProfiles: React.FC<FormProps> = ({ extSingletonProperty }) => {
  const [tabValue, setTabValue] = useState(0)
  const [ownershipItems, setOwnershipItems] = useState<BuildingsType>(null)
  const [contractItems, setContractItems] = useState<ContractsType>(null)
  const [offerItems, setOfferItems] = useState<OffersType>(null)
  const [tenancyItems, setTenancyItems] = useState<ArrearsType>(null)
  const [applicationItems, setApplicationItems] =
    useState<ApplicationsType>(null)

  useEffect(() => {
    const ownerships = extSingletonProperty?.extBuildings?.map((building) => {
      return building
    })
    const contracts =
      extSingletonProperty.extBuildings?.flatMap((building) =>
        building?.extListings?.length > 0
          ? building.extListings.flatMap(
              (extListing) =>
                extListing?.extContracts?.map((contract) => contract) || [],
            )
          : [],
      ) || []

    const offers =
      extSingletonProperty.extBuildings?.flatMap((building) =>
        building?.extListings?.length > 0
          ? building.extListings.flatMap(
              (extListing) =>
                extListing?.extOffers?.map((contract) => contract) || [],
            )
          : [],
      ) || []

    const tenancies = extSingletonProperty.extBuildings?.flatMap(
      (building) =>
        building?.extManagements
          ?.flatMap(
            (management) =>
              management?.extArrears?.map((tenancy) => tenancy) || [],
          )
          .map((tenancy) => tenancy) || [],
    )

    const applications = extSingletonProperty.extBuildings?.flatMap(
      (building) =>
        building?.extManagements
          ?.flatMap(
            (management) =>
              management?.extApplications?.map((application) => application) ||
              [],
          )
          .map((application) => application) || [],
    )

    setOwnershipItems(ownerships)
    setContractItems(contracts)
    setOfferItems(offers)
    setTenancyItems(tenancies)
    setApplicationItems(applications)
  }, [extSingletonProperty])

  const handleChangeTab = (_, newValue: number) => {
    setTabValue(newValue)
  }

  const getArrayList = (tabValue) => {
    switch (tabValue) {
      case 0:
        return ownershipItems
      case 1:
        return contractItems
      case 2:
        return offerItems
      case 3:
        return tenancyItems
      case 4:
        return applicationItems
      default:
        return []
    }
  }
  const contactTypes = [
    'Ownerships',
    'Contracts',
    'Offers',
    'Tenancies',
    'Applications',
  ]

  return (
    <>
      <Tabs value={tabValue} onChange={handleChangeTab}>
        {contactTypes.map((contactType) => (
          <Tab key={contactType} label={contactType} />
        ))}
      </Tabs>
      <RecordComponentList
        arrayList={getArrayList(tabValue)}
        contactType={contactTypes[tabValue]}
        propertySingletonId={extSingletonProperty?.id}
      />
    </>
  )
}

export default RevenueProfiles
