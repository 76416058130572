import React from 'react'

import {
  createTheme,
  Badge as MuiBadge,
  ThemeProvider,
  BadgeProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4f46e5',
      light: '#4f46e5',
      dark: '#818cf8',
    },
    secondary: {
      main: '#6b7280',
    },
    error: {
      main: '#ef4444',
    },
  },
})

const CustomBadge = styled((props: BadgeProps) => (
  <ThemeProvider theme={theme}>
    <MuiBadge {...props} />
  </ThemeProvider>
))((props) => ({
  '& .MuiBadge-badge': {
    // right: -20,
    // top: 15,
    padding: '4px',
    backgroundColor: props.color ? props.color : '#ef4444',
    fontSize: '9',
  },
}))

export default CustomBadge
