import { blue, green, grey, red } from '@mui/material/colors'

export type ColourReturnType = {
  bg: string
  color: string
}

export const snugStatusToColor = (status: string) => {
  switch (status) {
    case 'Accepted':
    case 'Leased':
    case 'Offered':
      return { color: green[500], bg: green[100] }
    case 'Declined':
      return { color: red[500], bg: red[100] }
    case 'Applied':
    case 'WithOwner':
    case 'Processing':
    case 'AwaitingInfo':
    case 'DepositReceived':
    case 'Shortlisted':
      return { color: blue[500], bg: blue[100] }

    default:
      return { color: grey[500], bg: grey[100] }
  }
}

export const listingStatusToColor = (status: string) => {
  switch (status) {
    case 'sold':
    case 'leased':
      return { color: green[500], bg: green[100] }
    case 'withdrawn':
      return { color: red[500], bg: red[100] }
    case 'current':
      return { color: blue[500], bg: blue[100] }

    default:
      return { color: grey[500], bg: grey[100] }
  }
}
export const snugSplitStatus = (status: string) => {
  switch (status) {
    case 'WithOwner':
      return 'With Owner'
    case 'AwaitingInfo':
      return 'Awaiting Info'
    case 'PMWithdrawn':
      return 'PM Withdrawn'
    case 'DepositReceived':
      return 'Deposit Received'
    default:
      return status
  }
}

export const onCoverStatusToColor = (status: string) => {
  switch (status) {
    case 'quoted':
    case 'bound':
      return { color: blue[500], bg: blue[100] }
    case 'paid':
      return { color: green[500], bg: green[100] }
    case 'cancelled':
      return { color: red[500], bg: red[100] }
    default:
      return { color: grey[500], bg: grey[100] }
  }
}
export const ccStatusToColor = (status: string) => {
  switch (status) {
    case 'Lease Renewal':
    case 'Unassigned':
    case 'Duplicate':
      return { color: grey[500], bg: grey[100] }
    case 'Personal Callback':
    case 'Team Callback':
    case 'No Answer':
    case 'In Progress':
      return { color: blue[500], bg: blue[100] }
    case 'Sold':
      return { color: green[500], bg: green[100] }
    case 'Not Taking Property':
    case 'Not Consented':
    case 'Non Serviceable':
    case 'Past Connection Date':
    case 'Invalid Number':
    case 'Embedded Network':
    case 'Campaign Opt Out':
    case 'Already Arranged':
      return { color: red[500], bg: red[100] }
    default:
      return { color: grey[500], bg: grey[100] }
  }
}
export const relloStatusToColor = (status: string) => {
  switch (status) {
    case 'Expired':
      return { color: grey[500], bg: grey[100] }
    case 'Active':
    case 'Pending':
    case 'Submitted':
      return { color: blue[500], bg: blue[100] }
    case 'Complete':
      return { color: green[500], bg: green[100] }
    case 'Cancelled':
      return { color: red[500], bg: red[100] }
    default:
      return { color: grey[500], bg: grey[100] }
  }
}

export const innovaytStatusToColor = (status: string) => {
  switch (status) {
    case 'Active':
      return { color: blue[500], bg: blue[100] }
    case 'Complete':
      return { color: green[500], bg: green[100] }
    case 'Unsuccessful':
      return { color: red[500], bg: red[100] }
    default:
      return { color: grey[500], bg: grey[100] }
  }
}

export const rexContractStatusToColor = (status: string) => {
  switch (status) {
    case 'offer':
    case 'unconditional':
      return { color: blue[500], bg: blue[100] }
    case 'settled':
      return { color: green[500], bg: green[100] }
    case 'fallen':
      return { color: red[500], bg: red[100] }
    default:
      return { color: grey[500], bg: grey[100] }
  }
}
