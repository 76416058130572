import React from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import { ColourReturnType } from 'src/components/Revenue/Profiles/Utils'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import LeadStatusComponent from './LeadStatusComponent'

export type LeadStatusType = {
  status: string
  color: ColourReturnType
  url: string
}

interface FormProps {
  onClickFunction: () => void
  leadStatuses: LeadStatusType[]
}

const LeadComponent: React.FC<FormProps> = ({
  onClickFunction,
  leadStatuses,
}) => {
  const consentConfirm = useConfirm()
  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      className={'flex flex-row justify-center items-center'}
    >
      {leadStatuses.length > 0 &&
        leadStatuses?.map((lead, lIndex) => (
          <LeadStatusComponent
            key={lIndex}
            status={lead.status}
            color={lead.color}
            url={lead.url}
          />
        ))}
      {leadStatuses.length === 0 && (
        <Tooltip title={'Create'}>
          <div>
            <Button
              fullWidth={false}
              variant="outlined"
              className="min-w-[0] p-1 rounded-full"
              onClick={() => {
                consentConfirm({
                  title: 'Confirm Consent',
                  description:
                    'Please confirm you have obtained consent to submit personal data.',
                  confirmationText: 'Confirm',
                }).then(() => {
                  onClickFunction()
                })
              }}
            >
              {' '}
              <PlusIcon className="w-4 h-4" />
            </Button>
          </div>
        </Tooltip>
      )}
    </Stack>
  )
}

export default LeadComponent
