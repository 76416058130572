import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'

import DebounceTextInput from 'src/components/DebounceTextInput/DebounceTextInput'
import Accordion from 'src/components/Library/Accordion/Accordion'
import { default as EmptyComponent } from 'src/components/Library/Empty/Empty'
import { CellProperties } from 'src/components/Revenue/Profiles/RevenueProfilesCell/RevenueProfilesCell'
import RevenueProfilesDetailsCell from 'src/components/Revenue/Profiles/RevenueProfilesDetailsCell'

interface FormProps {
  addresses: CellProperties
}

const MAX_PROPERTIES_TO_SHOW = 100

const RevenueProfilesHeader: React.FC<FormProps> = ({ addresses }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [extBuildingsFiltered, setExtBuildingsFiltered] =
    useState<CellProperties>(addresses)

  useEffect(() => {
    if (searchTerm) {
      const filteredProperties = addresses?.filter((application) =>
        application.address.toLowerCase().includes(searchTerm.toLowerCase()),
      )

      setExtBuildingsFiltered(filteredProperties)
    } else {
      setExtBuildingsFiltered(addresses)
    }
  }, [searchTerm, addresses])

  return (
    <Box className={'w-full'}>
      <Stack spacing={2} className={'px-10 py-4'}>
        <DebounceTextInput
          defaultValue={searchTerm}
          onChange={setSearchTerm}
          name={'Search'}
          setKey={'Search'}
          className="bg-white"
          label={'Search A Property'}
        />
        {extBuildingsFiltered?.length > MAX_PROPERTIES_TO_SHOW && (
          <div className="place-items-center">
            <EmptyComponent
              title="Too Many Records"
              description="Please select an address to view"
            />
          </div>
        )}
        {extBuildingsFiltered?.length == 0 && (
          <div className="place-items-center">
            <EmptyComponent
              title="No Records Found"
              description="Please review your filters"
            />
          </div>
        )}
        {extBuildingsFiltered?.length <= MAX_PROPERTIES_TO_SHOW &&
          extBuildingsFiltered.map((extProperties) => (
            <Accordion
              key={extProperties.id}
              title={extProperties.address}
              expanded={false}
              customstyle={'bg-white'}
              borderstyle={'border border-gray-300 paddingY-2'}
            >
              <Box>
                <Stack spacing={2}>
                  <RevenueProfilesDetailsCell id={extProperties.id} />
                </Stack>
              </Box>
            </Accordion>
          ))}
      </Stack>
    </Box>
  )
}

export default RevenueProfilesHeader
