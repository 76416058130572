import React from 'react'

import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { Chip, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import { toast } from '@redwoodjs/web/toast'

import { setStringToTitleCase } from 'src/components/Automations/utils'
import { ColourReturnType } from 'src/components/Revenue/Profiles/Utils'

interface FormProps {
  status: string
  color: ColourReturnType
  url: string
}

const handleCopyDataToClipboard = (url) => {
  navigator.clipboard.writeText(url)
  toast.success('Copied', {
    duration: 2000,
    className: 'flex-column',
  })
}

const LeadStatusComponent: React.FC<FormProps> = ({ status, color, url }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Chip
        label={setStringToTitleCase(status)}
        style={{
          color: color.color,
          backgroundColor: color.bg,
        }}
        variant={'filled'}
        size={'small'}
      />

      {url && (
        <Tooltip title={`Copy Url: ${url}`}>
          <div>
            <Button
              fullWidth={false}
              variant="outlined"
              className="min-w-[0] p-1"
              onClick={() => {
                handleCopyDataToClipboard(url)
              }}
            >
              <DocumentDuplicateIcon className="w-3 h-3" />
            </Button>
          </div>
        </Tooltip>
      )}
    </Stack>
  )
}

export default LeadStatusComponent
