import React, {
  BaseSyntheticEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import {
  BellSnoozeIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid'
import { Divider, Menu, MenuItem } from '@mui/material'
import dayjs from 'dayjs'

import { navigate } from '@redwoodjs/router'

import LoadingSpinner from 'src/components/Library/Loading'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import Empty from '../Library/Empty'
import IconButton from '../Library/IconButton'

import NotificationItem from './NotificationItem'
import { NotificationItemUI } from './types'
import useNotification from './useNotification'
import { getRouteForNotification, mapper } from './utils'

interface Props {
  setUnreadCount: Dispatch<SetStateAction<number>>
}

const Notifications: FC<Props> = (props) => {
  const [notifications, setNotifications] = useState<NotificationItemUI[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const { trackEvent } = useAnalytics()

  const {
    mutationsInProgress,
    getNotifications,
    getNotificationsResult,
    dismissNotification,
    updateNotification,
    updateNotifications,
    clearAllNotifications,
  } = useNotification()

  const unreadCount = useMemo(
    () => notifications.filter((n) => !n.readDate).length,
    [notifications],
  )

  useEffect(() => {
    if (!loading && !hasError) {
      props.setUnreadCount(unreadCount)
    }
  }, [hasError, loading, props, unreadCount])

  useEffect(() => {
    getNotifications()
    // Only run once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mutationsInProgress.length > 0) {
      return
    }

    if (getNotificationsResult.error) {
      setLoading(false)
      setHasError(true)
    }

    if (getNotificationsResult.data) {
      setLoading(false)
      setHasError(false)

      const newNotifications =
        getNotificationsResult.data.notificationsForMember
          .map(mapper.toUI)
          .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))

      setNotifications(newNotifications)
    }
  }, [mutationsInProgress, getNotificationsResult])

  const handleItemClick = useCallback(
    (route: string, notification: NotificationItemUI) =>
      (_event: BaseSyntheticEvent) => {
        if (!notification.readDate) {
          // Update local state
          setNotifications((prev) => {
            const result = prev.map((currNotification) => {
              if (currNotification.id === notification.id) {
                return {
                  ...currNotification,
                  readDate: dayjs(),
                }
              }

              return currNotification
            })

            return result
          })

          updateNotification({
            variables: {
              id: notification.id,
              readDate: dayjs().toISOString(),
            },
          })
        }

        if (route) {
          navigate(route)
        }
      },
    [updateNotification],
  )

  const onClearAll = useCallback(() => {
    setIsMenuOpen(false)
    setLoading(true)
    trackEvent('Notifications', 'clear all')
    return clearAllNotifications({
      onCompleted: () => {
        setNotifications([])
        setLoading(false)
      },
      onError: () => {
        setLoading(false)
      },
    })
  }, [])

  const getHandleItemClick = useCallback(
    (notification: NotificationItemUI) => {
      const route = getRouteForNotification(notification)

      if (!route && notification.readDate) {
        return null
      }

      return handleItemClick(route, notification)
    },
    [handleItemClick],
  )

  const getHandleItemDismiss = useCallback(
    (notification: NotificationItemUI) => (_event: BaseSyntheticEvent) => {
      dismissNotification({
        variables: {
          id: notification.id,
        },
      })

      setNotifications((prev) => {
        return prev.filter((n) => n.id !== notification.id)
      })
    },
    [dismissNotification],
  )

  const handleMarkAllAsRead = useCallback(
    (event: BaseSyntheticEvent) => {
      event.preventDefault()

      if (!unreadCount) {
        return
      }

      updateNotifications({
        variables: {
          readDate: dayjs().toISOString(),
        },
      })

      setNotifications((prev) => {
        const result = prev.map((notification) => {
          if (notification.readDate) {
            return notification
          }

          return {
            ...notification,
            readDate: dayjs(),
          }
        })

        return result
      })

      trackEvent('Notifications', 'mark all as read', {
        unReadCount: unreadCount,
      })
    },
    [unreadCount, updateNotifications],
  )

  return (
    <div>
      <div
        className={
          'flex items-center justify-between text-sm font-semibold border-b pb-2 mb-2'
        }
      >
        <span>Notifications</span>
        {!!notifications.length && (
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
              setIsMenuOpen(true)
            }}
          >
            <EllipsisVerticalIcon className="w-5 h-5" />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        >
          <MenuItem
            disabled={!unreadCount}
            onClick={(e) => {
              handleMarkAllAsRead(e)
              setIsMenuOpen(false)
            }}
          >
            {'Mark all as read'}
          </MenuItem>
          <Divider />
          <MenuItem className={'text-red-500'} onClick={onClearAll}>
            {'Clear all'}
          </MenuItem>
        </Menu>
      </div>

      <div className="max-h-[460px] overflow-y-scroll">
        {hasError && (
          <div>
            <Empty
              title="Error"
              description="Failed to load notifications"
              icon={
                <ExclamationCircleIcon className={'w-40 h-40 text-gray-200'} />
              }
            />
          </div>
        )}
        {!hasError && (
          <>
            {loading && (
              <div className="h-40">
                <LoadingSpinner />
              </div>
            )}
            {!loading && (
              <>
                {!!notifications.length &&
                  notifications.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      onClick={getHandleItemClick(notification)}
                      onDismiss={getHandleItemDismiss(notification)}
                    />
                  ))}
                {!notifications.length && (
                  <Empty
                    title="No Notifications"
                    icon={
                      <BellSnoozeIcon className={'w-40 h-40 text-gray-200'} />
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Notifications
