import { useContext } from 'react'

import LiveFeatureContext from 'src/lib/hooks/LiveFeatureFlag/LiveFeatureContext'
import { useAuth } from 'src/Providers'

const useLiveFeature = (feature: string) => {
  const { currentUser } = useAuth()

  // always show the feature if we are in CI
  // I want to rule this function out as the culprit of the Maximum call stack size exceeded error
  if (currentUser.isCI) return [true]
  const { flags } = useContext(LiveFeatureContext)
  const flag = flags.get(feature)
  if (!flag) {
    return [false]
  }
  const result: boolean = flags.get(feature)[0]
  return [result]
}

export default useLiveFeature
