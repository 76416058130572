import type React from 'react'

import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'

interface StyledTabsProps {
  children?: React.ReactNode
  value: number | string
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void
  className?: string
  variant?: 'standard' | 'scrollable' | 'fullWidth'
  centered?: boolean
  style?: React.CSSProperties
}

const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} />)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#6366f1',
  },
  borderBottom: '1px solid #e8e8e8',
  variant: 'standard',
})

export default StyledTabs
