import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Box, ButtonBase, Checkbox, Divider, Grid, Stack } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DetailedTableRecord } from 'api/src/common/baserowImporterTypes'
import {
  updateBaserowImportRequest,
  updateBaserowImportRequestVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import { QUERY as FindAirtableToBaserowRequest } from 'src/components/BaserowImporter/BaserowImporterRecordCell'
import { CellMigrationType } from 'src/components/BaserowImporter/BaserowImporterRecordCell/BaserowImporterRecordCell'
import { getItemMatchStatus } from 'src/components/BaserowImporter/helpers'
import { UPDATE_IMPORTER_REQUEST } from 'src/components/BaserowImporter/utils'
import IconButton from 'src/components/IconButton/IconButton'

interface BaserowImporterMainSectionProps {
  sortedTableListData: DetailedTableRecord[]
  setSelectedTableIndex: Dispatch<SetStateAction<number>>
  selectedTableIndex: number
  migrationRequest: CellMigrationType
}

const BaserowImporterSectionLeftTableMenu = ({
  sortedTableListData,
  setSelectedTableIndex,
  selectedTableIndex,
  migrationRequest,
}: BaserowImporterMainSectionProps) => {
  const [migrateTables, setMigrateTables] = useState(false)
  const [migrateFields, setMigrateFields] = useState(false)
  const [migrateViews, setMigrateViews] = useState(false)
  const [migrateRecords, setMigrateRecords] = useState(false)
  const [migrateLinks, setMigrateLinks] = useState(false)
  const [migrateComments, setMigrateComments] = useState(false)
  const [numRows, setNumRows] = useState(-1)

  const [updateRequest] = useMutation<
    updateBaserowImportRequest,
    updateBaserowImportRequestVariables
  >(UPDATE_IMPORTER_REQUEST, {
    onCompleted: (_data) => {
      toast.success('Job updated successfully')
    },
    refetchQueries: ['FindAirtableToBaserowRequest'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const handleToggleMigrationOption = async (
    option: keyof CellMigrationType,
  ) => {
    const newValue = !migrationRequest[option]

    switch (option) {
      case 'migrateTables':
        setMigrateTables(newValue)
        break
      case 'migrateFields':
        setMigrateFields(newValue)
        break
      case 'migrateViews':
        setMigrateViews(newValue)
        break
      case 'migrateRecords':
        setMigrateRecords(newValue)
        break
      case 'migrateLinks':
        setMigrateLinks(newValue)
        break
      case 'migrateComments':
        setMigrateComments(newValue)
        break
      default:
        break
    }
    await updateRequest({
      variables: {
        input: {
          id: migrationRequest.id,
          [option]: newValue,
        },
      },
    })
  }

  const handleUpdateRows = async () => {
    await updateRequest({
      variables: {
        input: {
          id: migrationRequest.id,
          maxMigrationRows: numRows || -1,
        },
      },
    })
  }

  useEffect(() => {
    setMigrateComments(migrationRequest.migrateComments)
    setMigrateFields(migrationRequest.migrateFields)
    setMigrateLinks(migrationRequest.migrateLinks)
    setMigrateRecords(migrationRequest.migrateRecords)
    setMigrateTables(migrationRequest.migrateTables)
    setMigrateViews(migrationRequest.migrateViews)
    setNumRows(migrationRequest.maxMigrationRows || -1)
  }, [migrationRequest])

  return (
    <Stack>
      <Typography
        className="text-lg font-semibold text-gray-800 p-2"
        variant="h6"
      >
        Migration Options
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={1} className="p-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={migrateTables}
                  onChange={() => handleToggleMigrationOption('migrateTables')}
                />
              }
              label="Migrate Tables"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={migrateFields}
                  onChange={() => handleToggleMigrationOption('migrateFields')}
                />
              }
              label="Migrate Fields"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={migrateViews}
                  onChange={() => handleToggleMigrationOption('migrateViews')}
                />
              }
              label="Migrate Views"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} className="p-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={migrateRecords}
                  onChange={() => handleToggleMigrationOption('migrateRecords')}
                />
              }
              label="Migrate Data"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={migrateLinks}
                  onChange={() => handleToggleMigrationOption('migrateLinks')}
                />
              }
              label="Migrate Links"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={migrateComments}
                  onChange={() =>
                    handleToggleMigrationOption('migrateComments')
                  }
                />
              }
              label="Migrate Comments"
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems={'center'} px={2}>
            <Grid item xs={6}>
              <Typography>Restrict Rows (-1 For All)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" spacing={1} alignItems={'center'} px={2}>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={numRows}
                  fullWidth={false}
                  onChange={(e) => {
                    setNumRows(parseInt(e.target.value))
                  }}
                />
                <IconButton onClick={handleUpdateRows}>
                  <CheckIcon className="h-6 w-6" />
                </IconButton>
              </Stack>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <Divider variant="middle" className="border-t-2 border-gray-200 my-2" />
      <Typography
        className="text-lg font-semibold text-gray-800 p-2"
        variant="h6"
      >
        Migration Tables
      </Typography>
      {sortedTableListData?.length > 0 && (
        <Box>
          {sortedTableListData.map((table: DetailedTableRecord, index) => (
            <ButtonBase
              className={`w-full text-left  rounded hover:bg-gray-100 ${
                selectedTableIndex === index
                  ? 'bg-indigo-100 text-indigo-500'
                  : ''
              }`}
              key={index}
              onClick={() => {
                setSelectedTableIndex(index)
              }}
            >
              <Stack direction="row" spacing={2} className="p-2 w-full">
                {getItemMatchStatus(table, 'Table')}
                <Typography className="w-[50%] text-xs truncate">
                  {table.name}
                </Typography>
              </Stack>
            </ButtonBase>
          ))}
        </Box>
      )}
    </Stack>
  )
}

export default BaserowImporterSectionLeftTableMenu
