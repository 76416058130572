import React, { FC, useEffect, useState } from 'react'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ClientStatus } from 'types/graphql'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import Button from 'src/components/Library/Button'
import MembershipSwitch from 'src/components/MembershipSwitch/MembershipSwitch'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'web/src/Providers'

const paymentFailureText = {
  buttonText: 'Update Billing',
  dialog:
    'Your Hubs account is restricted due to an overdue invoice.\n\n' +
    'To prevent your account from being suspended, please ensure your payment details are up to date. \n' +
    'For further enquiries please contact support, or your account manager.',
  onClick: () => navigate(routes.settingsClientBilling()),
}
const bannedText = {
  buttonText: 'Logout',
  dialog:
    'For further enquiries please contact support, or your account manager',
  onClick: () => navigate(routes.logout()),
}
const inactiveText = {
  buttonText: 'Logout',
  dialog:
    'For further enquires please contact support, or your account manager',
  onClick: () => navigate(routes.logout()),
}

const getReason = (clientStatus: ClientStatus) => {
  switch (clientStatus) {
    case 'PAUSED':
      return paymentFailureText
    case 'SUSPENDED':
      return bannedText
    case 'INACTIVE':
      return inactiveText
    default:
      return { buttonText: '', dialog: '', onClick: () => {} }
  }
}

const shouldShowClientPauseDialog = (
  clientStatus: ClientStatus,
  userRoles: string[],
  pathName: string,
) => {
  if (
    userRoles.includes('SUPERADMIN') ||
    userRoles.includes('STAFFLINK') ||
    pathName.includes(routes.settingsClientBilling()) ||
    clientStatus === 'ACTIVE'
  ) {
    return { showDialog: false, reason: getReason(clientStatus) }
  } else if (
    (clientStatus === 'PAUSED' && userRoles.includes('OWNER')) ||
    userRoles.includes('ADMIN')
  ) {
    return { showDialog: true, reason: getReason(clientStatus) }
  } else if (clientStatus === 'SUSPENDED' || clientStatus === 'INACTIVE') {
    return { showDialog: true, reason: getReason(clientStatus) }
  } else {
    return { showDialog: false, reason: getReason(clientStatus) }
  }
}

const ClientPauseDialog: FC = () => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { currentUser } = useAuth()
  const { pathname } = useLocation()
  const [clientName, setClientName] = useState(currentUser?.parentData.name)
  const [clientStatus, setClientStatus] = useState(
    currentUser?.parentData.status,
  )

  const [showClientPauseDialog, setShowClientPauseDialog] = useState(false)
  const [pausedReason, setPausedReason] = useState(getReason(clientStatus))
  const [membershipSwitchOpen, setMembershipSwitchOpen] = useState(false)

  useEffect(() => {
    if (currentUser) {
      setClientName(currentUser.parentData.name)
      setClientStatus(currentUser.parentData.status)
      const { reason, showDialog } = shouldShowClientPauseDialog(
        clientStatus,
        currentUser.roles,
        pathname,
      )
      setShowClientPauseDialog(showDialog)
      setPausedReason(reason)
    }
  }, [currentUser, pathname])

  const handleClose = () => {
    setShowClientPauseDialog(false)
  }

  const { trackEvent } = useAnalytics()

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={showClientPauseDialog}
        onClose={() => {}}
        maxWidth="lg"
        sx={{
          backdropFilter: 'blur(2px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
            alignItems: 'center',
            padding: '2rem',
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="text-red-500 font-bold text-center capitalize"
          >
            The {clientName} Account is {clientStatus.toLowerCase()}
          </DialogTitle>
          <DialogContent className="text-center">
            <DialogContentText className="text-center whitespace-pre-line">
              {pausedReason.dialog}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              width: '300px',
            }}
          >
            <Button
              onClick={() => {
                pausedReason.onClick()
              }}
            >
              {pausedReason.buttonText}
            </Button>
            <Button
              variant="outlined"
              onClick={() => window.Intercom('showNewMessage')}
            >
              Contact Us
            </Button>
          </DialogActions>
          <DialogActions>
            {currentUser.userData.memberships?.length > 1 && (
              <Button
                variant="text"
                onClick={() => {
                  trackEvent('Settings', 'Switch Account')
                  setMembershipSwitchOpen(true)
                  setShowClientPauseDialog(false)
                }}
              >
                Switch Membership
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
      <MembershipSwitch
        isModalOpen={membershipSwitchOpen}
        closeModal={() => {
          setMembershipSwitchOpen(false)
          setShowClientPauseDialog(true)
        }}
      />
    </>
  )
}

export default ClientPauseDialog
