import { toast } from '@redwoodjs/web/dist/toast'

import { isNumeric } from './baserow/modules/core/utils/string'

export const toFirstDecimal = (value: string | number) => {
  const num = Number(value)
  return (Math.round(num * 10) / 10 || 0).toString()
}

export const toSecondDecimal = (value: string | number) => {
  const num = Number(value)
  return (Math.round(num * 100) / 100 || 0).toString()
}

export const openWindowWithBlockCheck = (
  url: string,
  target: string = '_blank',
) => {
  // This is the only valid use of window.open
  // eslint-disable-next-line no-restricted-syntax
  const newWindow = window.open(url, target)

  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed === 'undefined'
  ) {
    toast.error('Please allow pop-ups for this site', {
      duration: 5000,
    })
  }

  return newWindow
}

export const validateBaserowNumberInput = (value: number): boolean => {
  // Check for decimal
  const numStringSplit = String(value).split('.')

  if (numStringSplit?.length > 2) {
    // More than one decimal
    return false
  }

  // No characters on either side of decimal
  if (!numStringSplit[0] && !numStringSplit[1]) {
    return false
  }

  // Check each decimal side for valid numbers
  for (const numString of numStringSplit) {
    // If part is valid number and exists
    //  e.g. ( .22 ) is valid with BR and will update to 0.22 on save
    if (!isNumeric(numString) && !!numString) {
      return false
    }
  }
  return true
}

export const transformBaserowNumberInput = (
  value: string,
  decimalPlaces: number,
  allowNegative: boolean,
): string => {
  if (!value) return null

  // Allow negative or convert to positive
  const transformWithNegative = !allowNegative
    ? Math.abs(Number(value))
    : Number(value)

  // Limit to number of decimal places
  // Less than Nth Decimal is allowed by BR, but over Nth decimal will not save
  return String(transformWithNegative?.toFixed(decimalPlaces))
}
