// External libraries and modules
import React, { FC, forwardRef, Fragment, ReactNode, useState } from 'react'

import { Menu, Transition } from '@headlessui/react'
import {
  ArrowLeftOnRectangleIcon,
  CogIcon,
  CreditCardIcon,
  LifebuoyIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { Avatar, Divider, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { FEATURE } from 'api/src/common/enums'
import {
  UpdateUserSidebarMutation,
  UpdateUserSidebarMutationVariables,
} from 'types/graphql'
import { useMediaQuery } from 'usehooks-ts' // Moved useMediaQuery here

import { back, navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

// Internal components and files
import MembershipSwitch from 'src/components/MembershipSwitch/MembershipSwitch'
import ToDos from 'src/components/ToDo/ToDos'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useFeature from 'src/lib/hooks/useFeature'
import { useAuth } from 'web/src/Providers'

import AliasSwitcherCell from '../AliasSwitcherCell'
import GlobalSearch from '../GlobalSearch'
import Notifications from '../Notifications/Notifications'

interface Props {
  title: string
  category?: string
  backNavigation?: boolean
  tabs?: boolean
  parentDataTestId?: string
  refreshClient?: boolean
  children?: ReactNode
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const UPDATE_USER = gql`
  mutation UpdateUserSidebarMutation($input: UpdateUserInput!, $id: Int!) {
    updateUser(input: $input, id: $id) {
      activeMembershipId
    }
  }
`

interface MenuLinkProps {
  href: string
  refreshClient: boolean
  className: string
  children?: ReactNode
}

const MenuLink: FC<MenuLinkProps> = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  MenuLinkProps
>(({ href, children, refreshClient, className }, ref) => {
  return refreshClient ? (
    <a
      ref={ref as React.RefObject<HTMLAnchorElement>}
      href={href}
      className={className}
    >
      {children}
    </a>
  ) : (
    <Button
      ref={ref as React.RefObject<HTMLButtonElement>}
      onClick={() => navigate(href)}
      className={className + ' w-full text-left'}
    >
      {children}
    </Button>
  )
})

const PageHeader: FC<Props> = ({
  title,
  category,
  backNavigation,
  parentDataTestId,
  refreshClient,
  children,
  tabs,
}) => {
  const isMobile = useMediaQuery('(max-width: 769px)')
  const { currentUser, hasRole } = useAuth()
  if (!currentUser?.userData?.userAgreementAccepted) {
    navigate(routes.endUserTermsAndConditionsPrompt())
  }

  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isAliasSwitcherOpen, setAliasSwitcherOpen] = useState(false)

  const { trackEvent } = useAnalytics()

  const [updateUserMutation] = useMutation<
    UpdateUserSidebarMutation,
    UpdateUserSidebarMutationVariables
  >(UPDATE_USER, {
    onCompleted: () => {
      setModalOpen(false)
      navigate(routes.home())
      location.reload()
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    },
  })

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const openAliasSwitcher = () => {
    setAliasSwitcherOpen(true)
  }

  const closeAliasSwitcher = () => {
    setAliasSwitcherOpen(false)
  }

  const selectAlias = (clientId: number) => {
    if (clientId === 1) {
      // eslint-disable-next-line no-console
      console.error('Alias to Stafflink is not supported')
      return
    }

    updateUserMutation({
      variables: {
        id: currentUser.userData.id,
        input: {
          clientAlias: clientId,
        },
      },
    })
  }

  const onClickLogout = () => {
    navigate(routes.logout())
  }

  return (
    <div
      className="flex-row flex items-center w-full py-2.5 bg-white-400 px-2 sm:px-6 border-b border-gray-200 bg-white justify-between md:visible pl-3 sm:pl-16 lg:pl-6 "
      data-testid={parentDataTestId}
    >
      <div className="flex flex-1 justify-start items-center h-full max-w-full">
        {!isMobile && (
          <div className="flex items-center">
            {backNavigation && (
              <IconButton
                onClick={back}
                className="mr-1"
                data-testid="header-back-button"
              >
                <ChevronLeftIcon className="h-6 w-6 text-gray-700 bg-white rounded-md p-1 !border border-gray-200 !m-0 hover:bg-gray-100" />
              </IconButton>
            )}
            <Typography
              variant="caption"
              display="block"
              className="text-gray-500 font-bold leading-4"
            >
              {category}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className="text-lg leading-7 font-medium text-gray-900 tracking-tight mr-2 pr-2"
            >
              {title}
            </Typography>
          </div>
        )}
        {children && (
          <div
            className={classNames(
              tabs ? 'items-end w-full' : '',
              'flex flex-1 h-full items-center',
            )}
          >
            {children}
          </div>
        )}
      </div>
      <div className="flex relative ">
        <div className="flex items-center gap-3 sm:gap-4">
          <Divider orientation="vertical" />
          <GlobalSearch isMobile={isMobile} />
          <ToDos />
          <Notifications />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <div className="flex">
              <Menu.Button
                className="bg-white rounded-full p-0.5"
                onClick={() => {
                  setProfileMenuOpen(!profileMenuOpen)
                  trackEvent('Uncategorized', 'profileMenu', {
                    openProfileMenu: !profileMenuOpen,
                  })
                }}
              >
                <span className="sr-only">Open user menu</span>
                <Avatar
                  className="w-10 h-10 rounded-full"
                  src={currentUser?.userData?.avatarUrl}
                  data-testid="top-bar-expand-profile"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-1 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[103] p-2">
                <div>
                  <span className="flex-1 flex flex-col min-w-0 p-2 border-b border-gray-200 m-1">
                    <span className="text-gray-700 text-xs font-medium truncate">
                      {currentUser?.userData.name}
                    </span>
                    <span className="text-gray-500 text-xs">
                      {currentUser?.userData.position}
                    </span>
                  </span>
                  <Menu.Item>
                    {({ active }) => (
                      <MenuLink
                        href={
                          '/app/people/directory?membershipId=' +
                          currentUser.membershipData.id
                        }
                        className={classNames(
                          '' + active
                            ? 'hover:bg-gray-100 text-gray-700'
                            : 'text-gray-700',
                          'flex items-center justify-start px-2 py-2 text-sm normal-case rounded-md font-normal',
                        )}
                        refreshClient={refreshClient}
                      >
                        <UserIcon className="h-4 w-4 mr-2 text-gray-500" />
                        View Profile
                      </MenuLink>
                    )}
                  </Menu.Item>
                </div>
                {!isMobile && hasRole(['ADMIN', 'OWNER']) && (
                  <div>
                    <Menu.Item>
                      <MenuLink
                        href="/app/settings/client/configuration"
                        className={classNames(
                          'flex items-center text-gray-700 hover:bg-gray-100 justify-start px-2 py-2 text-sm normal-case rounded-md font-normal',
                        )}
                        refreshClient={refreshClient}
                      >
                        <CogIcon className="h-4 w-4 mr-2 text-gray-500" />
                        Client Settings
                      </MenuLink>
                    </Menu.Item>
                  </div>
                )}{' '}
                {!isMobile && hasRole(['ADMIN', 'OWNER']) && (
                  <div>
                    <Menu.Item>
                      <MenuLink
                        href="/app/settings/client/billing"
                        className={classNames(
                          'flex items-center text-gray-700 hover:bg-gray-100 justify-start px-2 py-2 text-sm normal-case rounded-md font-normal',
                        )}
                        refreshClient={refreshClient}
                      >
                        <CreditCardIcon className="h-4 w-4 mr-2 text-gray-500" />
                        Billing
                      </MenuLink>
                    </Menu.Item>
                  </div>
                )}
                {hasRole(['SUPERADMIN', 'STAFFLINK']) && (
                  <div className="">
                    <Menu.Item>
                      {({ active }) => (
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => {
                            if (currentUser.isClientAlias) {
                              selectAlias(null)
                            } else {
                              openAliasSwitcher()
                            }
                          }}
                          className={
                            'w-full text-left px-2 py-2 capitalize ' +
                            classNames(
                              active
                                ? 'hover:bg-gray-100 text-gray-900'
                                : 'hover:bg-gray-100 text-gray-700',
                              ' text-sm flex items-center justify-start rounded-md',
                            )
                          }
                          data-testid="top-bar-support-mode"
                        >
                          <LifebuoyIcon className="h-4 w-4 mr-2 text-gray-500" />
                          {currentUser.isClientAlias
                            ? 'Exit Support mode'
                            : 'Support mode'}
                        </Button>
                      )}
                    </Menu.Item>
                  </div>
                )}
                <div>
                  {currentUser.userData.memberships?.length > 1 && (
                    <Menu.Item>
                      {({ active }) => (
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => openModal()}
                          className={
                            'w-full text-left px-2 py-2 capitalize  ' +
                            classNames(
                              active
                                ? 'hover:bg-gray-100 text-gray-900'
                                : 'hover:bg-gray-100 text-gray-700',
                              ' text-sm flex items-center justify-start rounded-md',
                            )
                          }
                        >
                          <ArrowLeftOnRectangleIcon className="h-4 w-4 mr-2 text-gray-500" />
                          Change Active Membership
                        </Button>
                      )}
                    </Menu.Item>
                  )}
                </div>
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => onClickLogout()}
                        className={
                          'w-full text-left text-red-600 capitalize ' +
                          classNames(
                            active ? 'hover:bg-red-100 ' : '',
                            ' px-2 py-2 text-sm flex items-center justify-start rounded-md',
                          )
                        }
                      >
                        <ArrowLeftOnRectangleIcon className="h-4 w-4 mr-2 text-red-600" />
                        Logout
                      </Button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <MembershipSwitch closeModal={closeModal} isModalOpen={isModalOpen} />
      {isAliasSwitcherOpen && (
        <AliasSwitcherCell
          closeAliasSwitcher={closeAliasSwitcher}
          selectAlias={selectAlias}
        />
      )}
    </div>
  )
}

export default PageHeader
