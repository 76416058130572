import React from 'react'

import { ArrowPathIcon } from '@heroicons/react/24/outline'
import {
  createTheme,
  IconButton as MuiIconButton,
  IconButtonProps,
  ThemeProvider,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4f46e5',
      light: '#4f46e5',
      dark: '#818cf8',
    },
    secondary: {
      main: '#6b7280',
    },
    success: {
      main: '#16a34a',
    },
    error: {
      main: '#ef4444',
    },
  },
})

interface LibraryIconButtonProps extends IconButtonProps {
  className?: string
  loading?: boolean
  href?: string
  target?: string
}

const IconButton = styled((props: LibraryIconButtonProps) => (
  <ThemeProvider theme={theme}>
    {props.loading ? (
      <MuiIconButton disabled {...props} className={`${props.className}`}>
        <ArrowPathIcon className="w-5 h-5 stroke-2 animate-spin" />
      </MuiIconButton>
    ) : (
      <MuiIconButton
        disableRipple
        {...props}
        className={`${props.className} hover:bg-indigo-100`}
      />
    )}
  </ThemeProvider>
))(() => ({}))

export default IconButton
